import { Module } from 'vuex-smart-module';
import TimeslotsActions from './actions';
import TimeslotsGetters from './getters';
import TimeslotsMutations from './mutations';
import TimeslotsState from './state';

export default new Module({
  state: TimeslotsState,
  mutations: TimeslotsMutations,
  actions: TimeslotsActions,
  getters: TimeslotsGetters,
});
