import { Module } from 'vuex-smart-module';
import ClientsActions from './actions';
import ClientsGetters from './getters';
import ClientsMutations from './mutations';
import ClientsState from './state';

export default new Module({
  state: ClientsState,
  mutations: ClientsMutations,
  actions: ClientsActions,
  getters: ClientsGetters,
});
