
import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component
export default class BlockTitle extends Vue {
  @Prop({ type: Boolean, default: false })
  edit_btn_disabled!: boolean;

  get canShowCustomBtn() {
    return !!this.$scopedSlots['custom-btn'];
  }

  @Emit()
  edit_btn_clicked() {
    return true;
  }
}
