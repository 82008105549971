
import {
  Component,
  Emit,
  Vue,
} from 'vue-property-decorator';
import { SimpleDialog } from '@/components/dialogs/simple';

@Component({
  components: {
    SimpleDialog,
  },
})
export default class CreateClientWithoutEmailDialog extends Vue {
  @Emit()
  close() {
    return false;
  }

  @Emit()
  create_client() {
    this.close();
  }
}
