import { Timeslots } from '@/modules/timeslots/types';
import { AttractionTypes, AttractionStatuses } from '@/enums';
// eslint-disable-next-line import/no-cycle
import { Worktables } from '@/modules/worktables/types';
import { Instructor } from '@/modules/instructors/types';

export namespace Attractions {
  export enum Pages {
    ATTRACTIONS = 'attractions',
    ATTRACTION_DETAIL = 'attraction-detail',
    ATTRACTION_NEW = 'attractions-new',
    EDIT_ATTRACTION = 'attraction-edit'
  }
  export enum AttractionsStatuses {
    ACTIVE = 1,
    NOT_ACTIVE = 2,
  }
  export interface TimeslotTableCols {
    text: string,
    value: string,
    visible: boolean,
    selected: boolean,
    /**
     * Описание для админ части приложения
     * Если пусто вместо него выведется text
     */
    description?: string,
    /**
     * Фиксирование колонок, используется только для id и времени
     */
    fixed?: boolean
  }
  export type Attraction = {
    id?: number,
    type: AttractionTypes,
    status: AttractionStatuses,
    bitrix_id: number | null,
    prefix: string,
    title: string,
    group?: string,
    media_group?: string,
    weight_control: boolean,
    booking_without_names: boolean,
    min_weight: number | null,
    max_weight: number | null,
    min_total_weight?: number | null,
    max_total_weight?: number | null,
    min_age: number | null,
    seats: number,
    min_age_of_one?: number | null,
    expand?: boolean,
    worktables?: Worktables.Worktable[],
    timeslots: Timeslots.Timeslot[],
    min_weight_current?: number | null,
    max_weight_current?: number | null,
    min_age_current?: number | null,
    fields: Attractions.TimeslotTableCols[],
    instructors?: Instructor[],
  }
  export type AttractionCurrentData = Pick<
    Attraction,
    'id' | 'title' | 'min_weight_current' | 'max_weight_current' | 'min_age'
    >
}
