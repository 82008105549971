import { render, staticRenderFns } from "./UnlockTimeslotsDialog.vue?vue&type=template&id=37ede39e&"
import script from "./UnlockTimeslotsDialog.vue?vue&type=script&lang=ts&"
export * from "./UnlockTimeslotsDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports