var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pt-5"},[_c('v-row',[_c('v-col',[_c('v-chip',{attrs:{"outlined":""}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-circle-medium ")]),_vm._v(" Онлайн ")],1),(_vm.item.client?.id)?_c('v-chip',{staticClass:"ml-2",attrs:{"outlined":""},on:{"click":_vm.get_current_client_info}},[_vm._v(" "+_vm._s(_vm.item.client?.id)+" ")]):_vm._e()],1),_c('v-col',[_c('span',[_vm._v(" "+_vm._s(_vm.item.title)+" ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_vm._v(" Номер заказа ")]),(_vm.item.order)?_c('OrderSharedLink',{attrs:{"order":_vm.item.order}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',[_vm._v(" Стоимость ")]),_c('div',{staticClass:"black--text"},[_vm._v(" "+_vm._s(_vm.item.price)+" ₽ ")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('TextField',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Вес","hide-details":"","dense":"","readonly":_vm.is_access_denied,"require":_vm.params.weight_control,"rules":[
          v => !_vm.params.weight_control || _vm.is_require(v) || _vm.require_message,
          v => (!_vm.params.weight_control && !v)
            || _vm.is_int(v, {
              min: _vm.params.min_weight_current || 0,
              max: 3000,
            }) || _vm.invalid_field,
        ]},model:{value:(_vm.item.client.weight),callback:function ($$v) {_vm.$set(_vm.item.client, "weight", _vm._n($$v))},expression:"item.client.weight"}})],1),_c('v-col',{attrs:{"cols":"7"}},[_c('Select',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Медиапакет","hide-details":"","dense":"","item_value":"value","item_text":"text","readonly":_vm.is_access_denied,"items":_vm.media_select_options},model:{value:(_vm.item.media),callback:function ($$v) {_vm.$set(_vm.item, "media", $$v)},expression:"item.media"}})],1)],1),_c('TextField',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Фамилия","hide-details":"","dense":"","readonly":_vm.is_readonly_field,"require":!_vm.params.booking_without_names,"rules":[
      v => _vm.params.booking_without_names || _vm.is_require(v) || _vm.require_message
    ]},model:{value:(_vm.item.client.surname),callback:function ($$v) {_vm.$set(_vm.item.client, "surname", $$v)},expression:"item.client.surname"}}),_c('TextField',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Имя","hide-details":"","dense":"","readonly":_vm.is_readonly_field,"require":!_vm.params.booking_without_names,"rules":[
      v => _vm.params.booking_without_names || _vm.is_require(v) || _vm.require_message
    ]},model:{value:(_vm.item.client.firstname),callback:function ($$v) {_vm.$set(_vm.item.client, "firstname", $$v)},expression:"item.client.firstname"}}),_c('TextField',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Отчество","hide-details":"","dense":"","readonly":_vm.is_readonly_field},model:{value:(_vm.item.client.middlename),callback:function ($$v) {_vm.$set(_vm.item.client, "middlename", $$v)},expression:"item.client.middlename"}}),_c('TextArea',{staticClass:"rounded-0",attrs:{"label":"Комментарий","hide-details":"","dense":"","readonly":_vm.is_access_denied,"no-resize":""},model:{value:(_vm.comment),callback:function ($$v) {_vm.comment=$$v},expression:"comment"}}),_vm._v(" "),_c('TextField',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Emal","hide-details":"","dense":"","readonly":_vm.is_readonly_field,"require":!_vm.params.booking_without_names,"rules":[
      v => _vm.params.booking_without_names || _vm.is_require(v) || _vm.require_message,
      v => (_vm.params.booking_without_names && !v) || _vm.is_email(v) || _vm.invalid_email
    ]},model:{value:(_vm.item.client.email),callback:function ($$v) {_vm.$set(_vm.item.client, "email", $$v)},expression:"item.client.email"}}),_c('TextField',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.phone_number_mask),expression:"phone_number_mask"}],staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Телефон","hide-details":"","dense":"","validate-on-blur":"","readonly":_vm.is_readonly_field,"require":!_vm.params.booking_without_names,"rules":[
      v => _vm.params.booking_without_names || _vm.is_require(v) || _vm.require_message,
      v => (_vm.params.booking_without_names && !v) || _vm.is_correct_phone(v) || _vm.invalid_phone_number
    ]},model:{value:(_vm.item.client.phone),callback:function ($$v) {_vm.$set(_vm.item.client, "phone", $$v)},expression:"item.client.phone"}}),_c('TextField',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Страна","hide-details":"","dense":"","readonly":_vm.is_readonly_field,"require":!_vm.params.booking_without_names,"rules":[
      v => _vm.params.booking_without_names || _vm.is_require(v) || _vm.require_message
    ]},model:{value:(_vm.item.client.country),callback:function ($$v) {_vm.$set(_vm.item.client, "country", $$v)},expression:"item.client.country"}}),_c('TextField',{staticClass:"rounded-0",class:_vm.$style.formField,attrs:{"label":"Город","hide-details":"","dense":"","require":!_vm.params.booking_without_names,"readonly":_vm.is_readonly_field,"rules":[
      v => _vm.params.booking_without_names || _vm.is_require(v) || _vm.require_message
    ]},model:{value:(_vm.item.client.city),callback:function ($$v) {_vm.$set(_vm.item.client, "city", $$v)},expression:"item.client.city"}}),_c('v-divider')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }