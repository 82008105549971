import { Attractions } from '../types';
import { default_attraction, default_attraction_fields } from '../constans';

export default class AttractionsState {
  attractions: Attractions.Attraction[] = [];
  attractions_list: Attractions.Attraction[] = [];
  current_attraction!: Attractions.Attraction;
  attractions_expand = false;
  base_attraction: Attractions.Attraction = default_attraction();
  base_attraction_fields: Attractions.TimeslotTableCols[] = default_attraction_fields();
}
