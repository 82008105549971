
import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component
export default class SuccessDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  center_title!: boolean;

  @Prop({ type: Boolean, default: false })
  center_text!: boolean;

  @Emit()
  close() {
    return false;
  }
}
