
import {
  Component,
  Prop,
  Vue,
} from 'vue-property-decorator';

@Component
export default class TextField extends Vue {
  @Prop({ type: Boolean, default: false })
  blue_icon!: boolean;

  @Prop({ type: Boolean, default: false })
  require!: boolean;

  @Prop({ type: String, default: 'text' })
  type!: string;

  /**
   * можно ли указывать отрицательные значения
   */
  @Prop({ type: Boolean, default: false })
  allow_negative!: boolean;

  @Prop({
    type: [
      Number,
      String,
    ],
    default: -1,
  })
  max!: string;

  @Prop({
    type: [
      Number,
      String,
    ],
    default: 0,
  })
  min!: string;

  show_pass = false;

  get classes() {
    return [
      'text-field',
      'rounded-0',
      { blue_icon: this.blue_icon },
      { require: this.require },
    ];
  }

  paste(event: ClipboardEvent) {
    const pasteVal = event.clipboardData?.getData('Text');

    if (Number.isNaN(Number(pasteVal))) {
      event.preventDefault();
    }
  }
}
