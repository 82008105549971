import { Component, Vue } from 'vue-property-decorator';
import validator from 'validator';
import { ValidationMasks, ValidationMessages } from '@/enums';

const {
  equals,
  isEmail,
  isInt,
  isLength,
  isFloat,
  isURL,
} = validator;

@Component
export default class ValidateMixin extends Vue {
  is_equals = equals

  is_url = isURL

  get invalid_field() {
    return ValidationMessages.Messages.INVALID_FIELD;
  }

  get invalid_max_length() {
    return ValidationMessages.Messages.INVALID_MAX_LENGTH;
  }

  get require_message() {
    return ValidationMessages.Messages.REQUIRE;
  }

  get invalid_email() {
    return ValidationMessages.Messages.INVALID_EMAIL;
  }

  get phone_mask() {
    return ValidationMasks.PHONE;
  }

  get invalid_login_format() {
    return ValidationMessages.Messages.INVALID_LOGIN;
  }

  get invalid_phone_number() {
    return ValidationMessages.Messages.INVALID_PHONE_NUMBERS;
  }

  get invalid_password_format() {
    return ValidationMessages.Messages.INVALID_PASSWORD_FORMAT;
  }

  get invalid_auth_message() {
    return ValidationMessages.Messages.INVALID_AUTH_DATA;
  }

  get less_weight_message() {
    return ValidationMessages.Messages.WEIGHT_MUST_BE_LESS;
  }

  is_by_pattern(value: string, pattern: string): boolean {
    return !!value.match(new RegExp(pattern));
  }

  is_valid_login(v: string, count = 4): boolean {
    const login_pattern = new RegExp(/^\d+$/);

    return !!v.match(login_pattern) && v.length === 4;
  }

  is_valid_pass(v: string, count = 6): boolean {
    const validate = (pattern: string): boolean => this.is_by_pattern(v, pattern);

    return this.is_length(v, { min: count });
    // && validate('[A-Z]')
    // && validate('[a-z]')
    // && validate('[0-9]')
    // && validate('[!@#$%^&*]');
  }

  is_length(
    str: string | null,
    options?: { min?: number | undefined, max?: number | undefined },
  ) {
    if (str === null || typeof str === 'undefined') {
      return false;
    }

    return isLength(str, options);
  }

  /**
   *
   * @param val - значение которое проверяем
   * @returns возвращает TRUE когда значение НЕ пустое и FALSE когда наоборот
   */
  is_require(val: unknown): boolean {
    return (val !== undefined && val !== null && val !== '');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  is_required_when(val: any, condition: boolean): boolean {
    return condition ? this.is_require(val) : true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  is_float(val: any): boolean {
    if (val !== 0) {
      return isFloat(val.toString());
    }

    return true;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  is_int(
    val: any,
    options?: { min?: number | undefined, max?: number | undefined },
  )
    : boolean {
    return val === null || isInt(val.toString(), options);
  }

  is_email(val?: string | null): boolean {
    if (val !== undefined && val !== null) {
      const pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return pattern.test(val);
    }

    return false;
  }

  is_tin(val: string): boolean {
    return isInt(val) && (val.length === 10 || val.length === 12);
  }

  is_time(val: string): boolean {
    return /(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?/.test(val);
  }

  is_correct_phone(v: string) {
    // 18 - с учетом маски, пробелов и +
    return !!v && v.length === 18;
  }

  is_array_filled(v: unknown[] | undefined | null) {
    return Array.isArray(v) ? !!v.length : false;
  }

  is_more_then(num_1: number, num_2: number) {
    return num_1 > num_2;
  }
}
