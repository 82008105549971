export enum AttractionStatuses {
  /**
   * Аттракцион не активен
   */
  NOT_ACTIVE = 'no active',

  /**
   * Аттракцион активен
   */
  ACTIVE = 'active',
}

export enum AttractionTypes {
  /**
   * Типы аттракционов. Изменяют кол-во полей
   */
  SINGLE = 'single',
  TANDEM = 'tandem'
}

export const AttractionStatusesTranslate: Record<string, string> = {
  [AttractionStatuses.ACTIVE]: 'Активен',
  [AttractionStatuses.NOT_ACTIVE]: 'Не активен',
};

export const AttractionTypesTranslate: Record<string, string> = {
  [AttractionTypes.SINGLE]: 'Одиночный',
  [AttractionTypes.TANDEM]: 'Тандемный',
};

export const attraction_types = Object.keys(
  AttractionTypesTranslate,
).map(
  (f: string) => ({ text: AttractionTypesTranslate[f], value: f }),
);

export const attraction_statuses = Object.keys(
  AttractionStatusesTranslate,
).map(
  (f: string) => ({ text: AttractionStatusesTranslate[f], value: f }),
);
