
import { Component, Vue } from 'vue-property-decorator';
import { VToast } from '@/components';
import AppLayout from './layouts/components/AppLayout.vue';
import { global_store } from './store';

@Component({
  components: {
    AppLayout,
    VToast,
    global_store,
  },
})
export default class App extends Vue {

}

