export enum TicketsStatuses {
  BOOKING = 'booking',
  ACTIVE = 'active',
  ENTRANCE = 'entrance',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export const TicketCancelStatuses = [TicketsStatuses.COMPLETED];

export const TicketOrderedStatuses = [
  TicketsStatuses.ACTIVE,
  TicketsStatuses.BOOKING,
  TicketsStatuses.ENTRANCE,
];

export const TicketSaveStatuses = [
  TicketsStatuses.ACTIVE,
  TicketsStatuses.BOOKING,
  TicketsStatuses.ENTRANCE,
];

export const TicketsStatusesTranslate = {
  [TicketsStatuses.ACTIVE]: 'Не забронирован',
  [TicketsStatuses.COMPLETED]: 'Использован',
  [TicketsStatuses.CANCELED]: 'Возвращен',
  [TicketsStatuses.BOOKING]: 'Забронирован',
  [TicketsStatuses.ENTRANCE]: 'Частично использован',
};

export enum TicketsActions {
  ASSIGN = 'assign',
  REMOVE_ASSIGN = 'remove assign',
  EXECUTE = 'execute',
  CANCEL = 'cancel',
  REVERT_CANCEL = 'revert_cancel',
  UNCOMPLETE = 'uncomplete',
}
