export enum TimeslotStatuses {
  ASSIGN = 'assign',
  BLOCKED = 'blocked',
  NEW = 'new',
  RESERVED = 'reserved',
  FREE_SEATS = 'free_seats'
}

export const TimeslotStatusesTranslate = {
  [TimeslotStatuses.ASSIGN]: 'Привязан',
  [TimeslotStatuses.RESERVED]: 'Зарезервирован',
  [TimeslotStatuses.BLOCKED]: 'Заблокирован',
  [TimeslotStatuses.NEW]: 'Новый',
};

export function is_reserved(status: TimeslotStatuses) {
  return status === TimeslotStatuses.RESERVED;
}

export function is_blocked(status: TimeslotStatuses) {
  return status === TimeslotStatuses.BLOCKED;
}
