import { Module } from 'vuex-smart-module';
// eslint-disable-next-line import/no-cycle
import AttractionsActions from './actions';
import AttractionsGetters from './getters';
import AttractionsMutations from './mutations';
import AttractionsState from './state';

export default new Module({
  state: AttractionsState,
  mutations: AttractionsMutations,
  actions: AttractionsActions,
  getters: AttractionsGetters,
});
