import { Actions } from 'vuex-smart-module';
import { clients_store, orders_store } from '@/store';
import { CommentDTO } from '@/types';
import { ErrorHandlereMixin } from '@/mixins';
import { TicketsService } from '../services';
import TicketsGetters from './getters';
import TicketsMutations from './mutations';
import TicketsState from './state';
import { Tickets } from '../types';

const ticketsService = new TicketsService();
const errorHandlereMixin = new ErrorHandlereMixin();

export default class TicketsActions extends Actions<
  TicketsState,
  TicketsGetters,
  TicketsMutations,
  TicketsActions
> {
  async get_tickets() {
    try {
      const data = await ticketsService.get_tickets();

      this.mutations.SET_TICKETS(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async create_comment(payload: CommentDTO) {
    try {
      const data = await ticketsService.add_comment_to_ticket(payload);

      this.dispatch('update_ticket', data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  /**
   * @param ticket_id
   * Переводит билет в статус исполнен, соответствует действию оформить
   */
  async execute_ticket(ticket_id: number) {
    const data = await ticketsService.execute_ticket(ticket_id);

    this.dispatch('update_ticket', data);
  }

  /**
   * @param ticket_id - id билета
   * @param timeslot_id - id таймслота
   * Привязывает билет к таймслоту, аналогично действию забронировать
   */
  async assign_ticket(params: { ticket_id: number, timeslot_id: number }) {
    const data = await ticketsService.assign_ticket_to_timeslot(params.ticket_id, params.timeslot_id);

    this.dispatch('update_ticket', data);
  }

  /**
   * @param ticket_id - id билета
   * Отменяет возврат
   */
  async revert_cancel(ticket_id: number) {
    const data = await ticketsService.revert_cancel(ticket_id);

    this.dispatch('update_ticket', data);
  }

  /**
   * @param ticket_id
   * Отменяет оформление, возвращает билет в статус Активен, с подстатусом Забронирован.
   */
  async remove_assign_of_ticket(ticket_id: number) {
    const data = await ticketsService.remove_assign_of_ticket(ticket_id);

    this.dispatch('update_ticket', data);
  }

  async uncomplete_of_ticket(ticket_id: number) {
    const data = await ticketsService.uncomplete_of_ticket(ticket_id);

    this.dispatch('update_ticket', data);
  }

  /**
   * @param ticket_id - id билета
   * Возврат билета, устанавливается вручную кассиром, переводит в статус "Возвращён"
   */
  async cancel_ticket(params: {ticket_id: number, comment: string}) {
    const data = await ticketsService.cancel_ticket(params.ticket_id, params.comment);

    this.dispatch('update_ticket', data);
  }

  /**
   * @param ticket
   * Обновляет билет в других модулях
   */
  async update_ticket(ticket: Tickets.Ticket) {
    if (ticket && this.state.tickets) {
      this.mutations.UPDATE_TICKET(ticket);
    }

    if (clients_store.state.selected_client) {
      clients_store.actions.edit_ticket(ticket);
    }

    const { selected_order } = orders_store.state;

    if (selected_order && selected_order.tickets) {
      const idx = selected_order.tickets.findIndex((el: Tickets.Ticket) => el.id === ticket.id);

      if (idx !== -1) {
        Object.assign(selected_order.tickets[idx], ticket);
      }
    }
  }

  async update_tickets(tickets: Tickets.Ticket[]) {
    tickets.forEach((ticket) => {
      this.dispatch('update_ticket', ticket);
    });
  }
}
