import { Module } from 'vuex-smart-module';
import OrdersActions from './actions';
import OrdersGetters from './getters';
import OrdersMutations from './mutations';
import OrdersState from './state';

export default new Module({
  state: OrdersState,
  mutations: OrdersMutations,
  actions: OrdersActions,
  getters: OrdersGetters,
});
