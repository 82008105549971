
import { ErrorHandlereMixin } from '@/mixins';
import { orders_store } from '@/store';
import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { scrollTop } from '@/utils';
import { OrdersSharedType } from '../types';

@Component
export default class OrderSharedLink extends Mixins(ErrorHandlereMixin) {
  @Prop()
  order!: OrdersSharedType;

  async open_order(e: Event) {
    e.preventDefault();

    try {
      await orders_store.actions.get_order_by_id(this.order.id);
      this.$emit('order_selected');
      scrollTop();
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
