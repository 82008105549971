
import { Component, Mixins } from 'vue-property-decorator';
import Logo from '@/assets/icons/logo.svg?inline';
import AttractionIcon from '@/assets/icons/attraction-icon.svg?inline';
import WorktableIcon from '@/assets/icons/worktable-icon.svg?inline';
import { MenuItem } from '@/types';
import { PolicyMixin } from '@/mixins';
import {
  DropdownMenu,
  TextField,
  DatepickerMenuComponent,
} from '@/components';
import { global_store, auth_store } from '@/store';
import { Worktables } from '@/modules/worktables/types';
import { Attractions } from '@/modules/attractions/types';
import { Roles, RolesTranslate } from '@/enums';
// import { Users } from '@/modules/users/types';
import HeaderSearch from './HeaderSearch.vue';

@Component({
  components: {
    Logo,
    DatepickerMenuComponent,
    DropdownMenu,
    TextField,
    HeaderSearch,
  },
})
export default class HeaderApp extends Mixins(PolicyMixin) {
  settings_menu: MenuItem[] = [
    {
      title: 'Рабочие столы',
      link: Worktables.Pages.WORKTABLES_TABLE,
      icon: WorktableIcon,
    },
    {
      title: 'Аттракционы',
      link: Attractions.Pages.ATTRACTIONS,
      icon: AttractionIcon,
    },
    // {
    //   title: 'Сотрудники',
    //   link: Users.Pages.INDEX,
    //   icon: 'mdi-account-tie',
    // },
  ];

  profile_menu = [
    {
      title: 'Выйти',
      link: 'logout',
    },
  ]

  get is_show_search_field() {
    return this.$route.name === Worktables.Pages.WORKTABLE_DETAIL;
  }

  get role() {
    if (!auth_store.state.user.roles.length) {
      return '';
    }
    const role = auth_store.state.user.roles[0].name as unknown as Roles;

    return RolesTranslate[role];
  }

  get current_time() {
    return global_store.state.time;
  }

  set current_time(val: string) {
    this.current_time = val;
  }

  get app_loading() {
    return global_store.state.app_loading;
  }

  get user_name() {
    return auth_store.state.user.name;
  }

  get date() {
    return global_store.state.date;
  }

  set date(date) {
    global_store.mutations.SET_DATE(date);
  }

  on_date_change(date: string) {
    this.date = date;
  }
}
