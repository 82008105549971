import { Vue } from 'vue-property-decorator';

const filters: { [index: string]: (value?: any) => string } = {
  price_format: (value?: number | string | null) => {
    const num_val = Number(value);

    if (value !== null && !Number.isNaN(num_val)) {
      const rounded = Math.round(num_val);

      return Intl.NumberFormat('ru-Ru', {}).format(rounded);
    }

    return '';
  },
  date_format: (value?: string) => {
    if (value) {
      const date = new Date(value);

      return date.toLocaleString();
    }
    return '';
  },
  birthdate_format: (value?: string) => {
    if (value) {
      const date = new Date(value);

      return date.toLocaleString().split(',')[0];
    }
    return '';
  },
  phone_format: (value?: string) => {
    if (value) {
      return value.toString().replace(/[^0-9]/g, '')
        .replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5');
    }

    return '';
  },
};

export default function register_filters(): void {
  Object.keys(filters).forEach((f) => {
    Vue.filter(f, filters[f]);
  });
}

export const {
  price_format,
  date_format,
  birthdate_format,
  phone_format,
} = filters;
