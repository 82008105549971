import { Module } from 'vuex-smart-module';
import TicketsActions from './actions';
import TicketsGetters from './getters';
import TicketsMutations from './mutations';
import TicketsState from './state';

export default new Module({
  state: TicketsState,
  mutations: TicketsMutations,
  actions: TicketsActions,
  getters: TicketsGetters,
});
