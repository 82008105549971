export enum ApplicationTypes {
  NEW = 'new',
  OPEN = 'opened',
  COMPLETED = 'completed'
}

export const ApplicationStatusesColors = {
  [ApplicationTypes.NEW]: '#DA1984',
  [ApplicationTypes.OPEN]: '#FFF000',
  [ApplicationTypes.COMPLETED]: '#009B77',
};

export const ApplicationStatusesDescription = {
  [ApplicationTypes.NEW]: 'Ни один из таймслотов не забронирован',
  [ApplicationTypes.OPEN]: 'Один или несколько таймслотов не забронированы',
  [ApplicationTypes.COMPLETED]: 'Все таймслоты забронированы',
};
