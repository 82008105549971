
import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';

@Component
export default class TextArea extends Vue {
  @Prop({ type: Boolean, default: false })
  require!: boolean;

  @Prop({ type: String })
  width!: string;

  get classes() {
    return [
      'text-field',
      'rounded-0',
      { require: this.require },
    ];
  }

  get style() {
    return this.width ? `width: ${this.width}px` : '';
  }
}
