import { Module } from 'vuex-smart-module';
import WorktablesActions from './actions';
import WorktablesGetters from './getters';
import WorktablesMutations from './mutations';
import WorktablesState from './state';

export default new Module({
  state: WorktablesState,
  mutations: WorktablesMutations,
  actions: WorktablesActions,
  getters: WorktablesGetters,
});
