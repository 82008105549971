
import {
  Component, Mixins, Prop, Watch,
} from 'vue-property-decorator';
import { TextField, TextArea, Select } from '@/components';
import { ValidateMixin, PolicyMixin } from '@/mixins';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import OrderSharedLink from '@/modules/orders/shared/components/OrderSharedLink.vue';
import { tickets_media_packets_types, ValidationMasks } from '@/enums';
import { AttractionShared } from '@/modules/attractions/shared/types';
import { default_comment_by_ticket } from '@/modules/tickets/constans';
import { default_client } from '@/modules/clients/constans';
import { scrollTop } from '@/utils';
import { clients_store } from '@/store';

const { actions } = clients_store;

@Component({
  components: {
    TextArea,
    TextField,
    OrderSharedLink,
    Select,
  },
})
export default class TimeslotOnlineClientItem extends Mixins(ValidateMixin, PolicyMixin) {
  @Prop()
  item!: TicketsSharedType;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: false })
  params!: AttractionShared;

  @Watch('item.client.phone')
  update_phone_mask() {
    /*
    ** баг библиотеки v-mask:
    ** если програмно вставлять номер в input, маска не применяется, необходимо изменить маску, чтоб применилась
    */
    this.$nextTick(() => {
      this.phone_number_mask = ValidationMasks.YEAR;
      this.phone_number_mask = ValidationMasks.PHONE;
    });
  }

  phone_number_mask = ValidationMasks.PHONE;
  media_select_options = tickets_media_packets_types;

  mounted() {
    if (this.item && !this.item.client) {
      this.item.client = default_client();
    }
  }

  get comment() {
    const { comments } = this.item;

    if (!comments.length) {
      comments.push(default_comment_by_ticket());
    }

    return comments[comments.length - 1].comment;
  }

  set comment(new_comment: string) {
    const { comments } = this.item;

    comments[comments.length - 1].comment = new_comment;
  }

  get is_readonly_field() {
    return this.readonly || this.is_access_denied;
  }

  get is_access_denied() {
    return !this.check_permissions(this.permissions.Timeslots.MODIFY);
  }

  async get_current_client_info() {
    if (this.item.client?.id) {
      await actions.set_client_by_id(this.item.client?.id);

      scrollTop();
    }
  }
}
