import { HttpService } from '@/services/http-service';
import { Instructor, InstructorQuery } from '../types';

export class InstructorsService extends HttpService {
  readonly base_path = 'instructors';

  async add_new(payload: Instructor): Promise<Instructor> {
    const { data } = await this.http_client.post(this.base_path, payload);

    return data.data;
  }

  async get_instructors(payload: InstructorQuery): Promise<Instructor[]> {
    const { data } = await this.http_client.get(this.base_path, {
      params: payload,
    });

    return data.data;
  }
}
