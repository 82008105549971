
import { global_store } from '@/store';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class VToast extends Vue {
  showSnackbar = false;
  icon = 'mdi-check';
  timer = 2000;

  get params() {
    return global_store.state.snackbar;
  }

  @Watch('params')
  on_params_change() {
    this.showSnackbar = true;
  }
}
