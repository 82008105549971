
import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import SimpleDialog from '@/components/dialogs/simple/SimpleDialog.vue';
import { ValidateMixin } from '@/mixins';
import { TextArea } from '@/components';
import { worktables_store } from '@/store';
import { ClientsSharedType } from '@/modules/clients/shared/types';
import { Timeslots } from '../../types';

const { getters } = worktables_store;

@Component({
  components: {
    SimpleDialog,
    TextArea,
  },
})
export default class RemoveAssignOfTimeslotDialog extends Mixins(ValidateMixin) {
  @Prop()
  timeslot!: Timeslots.Timeslot|null;

  @Prop()
  client!: ClientsSharedType|ClientsSharedType[];

  get full_name() {
    let name = '';

    if (this.client && !Array.isArray(this.client)) {
      name = `для клиента ${this.client.middlename} ${this.client.firstname} ${this.client.surname}`;
    }

    return name;
  }

  get attraction_name() {
    let name: string|undefined = '';

    if (this.timeslot) {
      name = getters.get_by_id(this.timeslot.attraction_id)?.title;
    }

    return name ? `аттракциона «${name}»` : '';
  }

  submit() {
    this.$emit('submit');
  }
}
