import { HttpService } from '@/services/http-service';
import { CommentDTO } from '@/types';
import { Orders } from '../types';

export default class OrdersService extends HttpService {
  private readonly base_path = 'orders'

  async get_order_by_id(order_id: number): Promise<Orders.Order> {
    const { data } = await this.http_client.get(`${this.base_path}/${order_id}`);

    return data.data;
  }

  async add_comment_to_order(payload: CommentDTO): Promise<Orders.Order> {
    const { data } = await this.http_client.post(`${this.base_path}/${payload.id}/comment`, {
      comment: payload.comment,
    });

    return data.data;
  }
}
