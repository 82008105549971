
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SliderBtn extends Vue {
  @Prop({ type: String, default: '' })
  position!: 'left' | 'right';

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;
}
