import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/src/locale/ru';
import vars from '@/scss/export-colors.scss';

Vue.use(Vuetify);

const get_colors = (field: string) => {
  const fields = [
    'base', 'lighten5', 'lighten4', 'lighten3', 'lighten2', 'lighten1', 'darken1', 'darken2', 'darken3', 'darken4',
  ];

  return Object.fromEntries(
    fields.map((key) => [
      key, vars[`${field}_${key}`],
    ]),
  );
};
const vuetify = new Vuetify({
  lang: {
    locales: {
      ru,
    },
    current: 'ru',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: { ...get_colors('primary') },
        secondary: { ...get_colors('secondary') },
        accent: { ...get_colors('accent') },
        error: { ...get_colors('error') },
        warning: { ...get_colors('warning') },
        success: { ...get_colors('success') },
        anchor: '#00A3E0',
      },
    },
  },
});

export { vuetify };
