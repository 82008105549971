import { Component, Vue } from 'vue-property-decorator';
import { global_store } from '@/store';

@Component
export default class MessagesMixin extends Vue {
  show_error(message: string) {
    global_store.actions.show_snackbar({ color: 'red', message, icon: 'mdi-alert-circle-outline' });
  }

  show_success(message: string) {
    global_store.actions.show_snackbar({ color: 'green', message });
  }

  show_warning(message: string) {
    global_store.actions.show_snackbar({ color: 'var(--v-warning-darken3)', message, icon: 'mdi-alert-circle-outline' });
  }
}
