
import { clients_store, global_store, orders_store } from '@/store';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { php_classes } from '@/enums';
import { ClientsSharedType } from '@/modules/clients/shared/types';
import { OrdersSharedType } from '@/modules/orders/shared/types';
import { scrollTop } from '@/utils';
import { ErrorHandlereMixin } from '@/mixins';
import HeaderSearchListItem from './HeaderSearchListItem.vue';

@Component({
  components: {
    HeaderSearchListItem,
  },
})
export default class HeaderSearch extends Mixins(ErrorHandlereMixin) {
  entries = [];
  is_loading = false;
  model = '';
  search: string | null = null;

  get items() {
    return [
      ...global_store.state.search.clients,
      ...global_store.state.search.orders,
    ];
  }

  get component_types() {
    return php_classes;
  }

  @Watch('search')
  async on_search() {
    if (!this.search) {
      this.clear_search();
      return;
    }
    if (this.is_loading) return;
    try {
      this.is_loading = true;
      await global_store.actions.search(this.search).finally(() => this.is_loading = false);
    } catch (error) {
      this.handleServerError(error);
    }
  }

  select_search_item(item: OrdersSharedType | ClientsSharedType) {
    scrollTop();

    /**
     * @val Объект результатов поиска
     * @type Order | Client
     * Открывает карторчку клиента или заказа
     * в зависимости от выбранного объекта
     */
    this.model = '';
    this.clear_search();

    if (this.component_types.client === item.className) {
      const item_obj = item as ClientsSharedType;

      clients_store.actions.set_client(item_obj);
      this.model = item_obj.firstname + item_obj.surname;
    } else {
      const item_obj = item as OrdersSharedType;

      orders_store.actions.select_order(item_obj);
      this.model = `${item_obj.id}`;
    }
  }

  clear_search() {
    global_store.actions.clear_search();
  }

  search_filter(item: any) {
    return item;
  }
}
