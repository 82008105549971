
import { Vue, Component, Prop } from 'vue-property-decorator';
import SimpleDialog from '../simple/SimpleDialog.vue';

@Component({
  components: {
    SimpleDialog,
  },
})
export default class ConfirmDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  loading!: boolean;

  dialog = false;

  resolve: (value: boolean) => void = () => ({});

  reject: (value: boolean) => void = () => ({});

  message = '';

  title = '';

  get show() {
    return this.dialog;
  }

  set show(value) {
    this.dialog = value;

    if (value === false) {
      this.cancel();
    }
  }

  open(title: string, message?: string) {
    this.dialog = true;
    this.title = title;
    this.message = message || '';

    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  hide() {
    this.cancel();
  }

  agree() {
    this.resolve(true);
    this.dialog = false;
  }

  cancel() {
    this.resolve(false);
    this.dialog = false;
  }
}
