
import { TextArea } from '@/components';
import {
  Component,
  Mixins,
  Ref,
} from 'vue-property-decorator';
import { ValidateMixin } from '@/mixins';
import { VForm } from '@/types';
import SimpleDialog from '../simple/SimpleDialog.vue';

@Component({
  components: {
    TextArea,
    SimpleDialog,
  },
})
export default class CommentDialog extends Mixins(ValidateMixin) {
  @Ref()
  private readonly form!: VForm;

  form_valid = false;

  comment_text = '';

  submit() {
    if (this.form.validate()) {
      this.$emit('add_comment', this.comment_text);
      this.$emit('close');
    }
  }
}
