import { RouteConfig } from 'vue-router';
import { Statistics } from './types';

const routes: RouteConfig[] = [
  {
    path: `/${Statistics.Pages.STATISTICS}/:id`,
    name: Statistics.Pages.STATISTICS_DETAIL,
    component: () => import(/* webpackChunkName: "attractions" */ './views/StatisticsDetail.vue'),
    meta: {
      hide_sidebar: true,
    },
  },
];

export default routes;
