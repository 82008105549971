
import { Component, Prop, Vue } from 'vue-property-decorator';
import { phone_format } from '@/filters';
import { TicketsMediaPacketsTypesTranslate } from '@/enums';
import { Clients } from '../../types';

@Component({
  methods: { phone_format },
})
export default class ToeTagTableExpandArea extends Vue {
  @Prop()
  client!: Clients.ToeTag;

  ticketsMediaPacketsTypesTranslate = TicketsMediaPacketsTypesTranslate
}
