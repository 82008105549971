
import { Component, Vue } from 'vue-property-decorator';
import { Layouts } from '../types';

@Component
export default class AppLayout extends Vue {
  get layout() {
    if (!this.$route.name) {
      return '';
    }
    const layout = this.$route.meta?.layout || Layouts.DEFAULT;

    return () => import(/* webpackChunkName: "layout" */ `@/layouts/${layout}.vue`);
  }
}
