
import {
  Component, Mixins, Prop, Watch,
} from 'vue-property-decorator';
import { TextField, Select, TextArea } from '@/components';
import SimpleDialog from '@/components/dialogs/simple/SimpleDialog.vue';
import { ValidateMixin, PolicyMixin } from '@/mixins';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { tickets_media_packets_types, ValidationMasks } from '@/enums';
import { default_comment_by_ticket } from '@/modules/tickets/constans';
import { clients_store } from '@/store';
import { default_client } from '@/modules/clients/constans';
import { AttractionShared } from '@/modules/attractions/shared/types';
import { scrollTop } from '@/utils';

const { state, actions } = clients_store;

@Component({
  components: {
    TextField,
    TextArea,
    Select,
    SimpleDialog,
  },
})
export default class TimeslotOfflineClientItem extends Mixins(ValidateMixin, PolicyMixin) {
  @Prop()
  ticket!: TicketsSharedType;

  @Prop({ default: false })
  readonly!: boolean;

  @Prop({ default: false })
  params!: AttractionShared;

  @Watch('ticket.client.phone')
  update_phone_mask() {
    /*
     ** баг библиотеки v-mask:
     ** если програмно вставлять номер в input, маска не применяется, необходимо изменить маску, чтоб применилась
     */
    this.$nextTick(() => {
      this.phone_number_mask = ValidationMasks.YEAR;
      this.phone_number_mask = ValidationMasks.PHONE;
    });
  }

  media_select_options = tickets_media_packets_types;
  clients_do_not_matches = false;
  phone_number_mask = ValidationMasks.PHONE;

  mounted() {
    if (this.ticket && !this.ticket.client) {
      this.ticket.client = default_client();
    }
  }

  get comment() {
    const { comments } = this.ticket;

    if (!comments.length) {
      comments.push(default_comment_by_ticket());
    }

    return comments[comments.length - 1].comment;
  }

  set comment(new_comment: string) {
    const { comments } = this.ticket;

    comments[comments.length - 1].comment = new_comment;
  }

  get is_readonly_field() {
    return this.readonly || this.is_access_denied;
  }

  get is_access_denied() {
    return !this.check_permissions(this.permissions.Timeslots.MODIFY);
  }

  get disabled_fill_out_btn() {
    return Boolean(!state.selected_client);
  }

  get selected_client_attractions() {
    if (clients_store.state.selected_client === null) return [];

    const { email, firstname, middlename } = clients_store.state.selected_client;

    const selected_client = `${email} ${firstname} ${middlename}`;

    const toe_tag_client = clients_store.state.toe_tag_clients.find((i) => {
      const toe_tag_client = `${i.email} ${i.firstname} ${i.middlename}`;

      return toe_tag_client === selected_client;
    });

    if (toe_tag_client === undefined) return [];

    return toe_tag_client.application.application_attraction.map(
      ({ attraction }) => attraction.title,
    );
  }

  get includes_selected_client_attractions() {
    if (!this.selected_client_attractions.length) return true;
    return this.selected_client_attractions
      .map((attraction_title) => attraction_title.toLowerCase())
      .includes(this.params.title.toLowerCase());
  }

  validate_before_fill_out() {
    if (
      (!this.ticket.client_id || state.selected_client?.id === this.ticket.client_id)
      && this.includes_selected_client_attractions
    ) {
      this.fill_out();
      return;
    }

    this.clients_do_not_matches = true;
  }

  fill_out() {
    // код написан не правильно начало
    this.ticket.client = state.selected_client;

    const application_attraction = state.selected_toe_tag_client?.application?.application_attraction;

    if (!application_attraction) return;
    if (application_attraction.length === 0) return;

    const { title } = this.params;
    const findAttraction = application_attraction.find(
      ({ attraction }) => attraction.title === title,
    );
    const media_status = findAttraction?.media_status;

    if (media_status) {
      this.ticket.media = media_status;
    }
    // код написан не правильно конец
  }

  async get_current_client_info() {
    if (this.ticket.client?.id) {
      await actions.set_client_by_id(this.ticket.client?.id);

      scrollTop();
    }
  }
}
