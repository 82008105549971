
import {
  Component,
  Prop,
  Vue,
  Watch,
} from 'vue-property-decorator';
import moment from 'moment';
import { ValidationMasks } from '@/enums';
import TextField from '../text-field/TextField.vue';

@Component({
  components: {
    TextField,
  },
})
export default class DatepickerComponent extends Vue {
  mounted() {
    this.update_date(this.$attrs.value);
  }

  @Prop({ type: String, default: '' })
  type_date!: string;

  @Prop({ type: String, default: 'Дата' })
  label!: string;

  @Prop({ type: Boolean, default: false })
  small_calendar!: boolean;

  @Prop({ type: String, default: '' })
  error!: string;

  @Prop({
    type: [
      String,
      Array,
    ],
    default: '',
  })
  classes!: string | [];

  @Prop({ type: Boolean, default: false })
  hide_details!: boolean;

  @Prop({ type: Boolean, default: false })
  clearable!: boolean;

  @Prop({ type: Boolean, default: false })
  required!: boolean;

  @Prop({ type: Boolean, default: false })
  dense!: boolean;

  @Prop({ type: Array, default: () => ([]) })
  rules!: [];

  @Prop({ type: String, default: '' })
  max!: '';

  @Watch('date')
  on_change_date() {
    this.error_message = '';

    if (this.date) {
      this.text_date = moment(this.date).format('DD.MM.YYYY');
      this.$emit('input', this.text_date);
    } else {
      this.text_date = '';
    }
  }

  @Watch('$attrs.value')
  update_date(date: string) {
    if (date) {
      const mask = date.includes('-') ? 'YYYY-MM-DD' : 'DD.MM.YYYY';
      const parse_date = moment(date, mask).format('YYYY-MM-DD');

      this.date = parse_date;
    } else {
      this.date = null;
    }
  }

  menu = false;
  date: string | null = null;
  text_date = '';
  error_message = '';

  get date_mask() {
    return this.type_date === 'month' ? ValidationMasks.MONTH : ValidationMasks.YEAR;
  }

  get min_width() {
    return this.small_calendar ? '' : '290px';
  }

  change_text_date(text_date: string) {
    if (this.validate_date(text_date)) {
      this.date = moment(text_date, 'DD.MM.YYYY').format('YYYY-MM-DD');
    }
  }

  validate_date(date?: string): boolean {
    return !!date && moment(date, 'DD.MM.YYYY', true).isValid();
  }

  text_date_changed() {
    this.error_message = '';

    if (!this.text_date) {
      return;
    }

    const date_arr = this.text_date.split('.');

    if (!date_arr.length || date_arr.length <= 2) {
      this.error_message = 'Невалидная дата';
      this.emit_error();
      return;
    }

    const day = Number(date_arr[0]) || '';
    const month = Number(date_arr[1]) || '';
    const year = Number(date_arr[2]) || '';

    if (!day || !month || !year) {
      this.error_message = 'Невалидная дата';
      this.emit_error();
      return;
    }

    if (month < 1 || month > 12) {
      this.error_message = 'Невалидная дата';
      this.emit_error();
      return;
    }
    if (year < 1900) {
      this.error_message = 'Невалидная дата';
      this.emit_error();
      return;
    }

    if ((day && (day < 0 || day > 31)) || month > 12) {
      this.error_message = 'Невалидная дата';
      this.emit_error();
      return;
    }

    this.$emit('input', this.text_date);
  }

  emit_error() {
    this.$emit('update:error', this.text_date);
  }
}
