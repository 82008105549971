
import {
  Component, Prop, Mixins, Emit,
} from 'vue-property-decorator';
import {
  TextArea,
} from '@/components';
import { SimpleDialog } from '@/components/dialogs/simple';
import { ErrorHandlereMixin, ValidateMixin } from '@/mixins';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { nextTick } from 'process';
import { timeslots_store } from '@/store';

@Component({
  components: {
    TextArea,
    SimpleDialog,
  },
})
export default class TimeslotBlockedDialog extends Mixins(ErrorHandlereMixin, ValidateMixin) {
  @Prop()
  timeslot!: TimeslotSharedType

  @Emit()
  close() {
    return false;
  }

  updated() {
    nextTick(() => {
      this.comment = this.timeslot?.comments[0]?.comment || '';
    });
  }

  comment = '';
  form_valid = false;

  async save() {
    if (!this.form_valid) {
      return;
    }

    try {
      await timeslots_store.actions.comment({ timeslot: this.timeslot, payload: this.comment });
      this.close();
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
