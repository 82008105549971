import { SearchObject, SnackbarParams } from '@/types';
import { Mutations } from 'vuex-smart-module';
import GlobalState from './state';

export default class GlobalMutations extends Mutations<GlobalState> {
  SIDEBAR_TOGGLE(toggle: boolean) {
    this.state.is_mini_sidebar = toggle;
  }

  SET_TIME(val: string) {
    this.state.time = val;
  }

  SET_DATE(val: string) {
    this.state.date = val;
  }

  TOGGLE_APP_LOADING(val: boolean) {
    this.state.app_loading = val;
  }

  SET_SEARCH(payload: SearchObject) {
    this.state.search = payload;
  }

  CLEAR_SEARCH() {
    this.state.search = {
      orders: [],
      clients: [],
    };
  }

  SET_SNACKBAR(params: SnackbarParams) {
    this.state.snackbar = params;
  }
}
