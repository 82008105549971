
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DropdownMenuIcon extends Vue {
  @Prop({
    type: [
      String, Object,
    ],
    default: '',
  })
  icon!: string;

  @Prop({ type: Boolean, default: false })
  isActive!: boolean;

  get isMdiIcon() {
    return typeof this.icon === 'string';
  }

  get iconColor() {
    return this.isActive ? 'primary--text text--darken-3' : 'error--text';
  }
}
