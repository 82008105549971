import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { Mutations } from 'vuex-smart-module';
import { Attractions } from '@/modules/attractions/types';
import { Worktables } from '../types';
import WorktablesState from './state';

export default class WorktablesMutations extends Mutations<WorktablesState> {
  SET_WORKTABLES(worktables: Worktables.Worktable[]) {
    this.state.worktables = worktables;
  }

  SET_CURRENT_WORKTABLE(worktable: Worktables.Worktable) {
    this.state.current_worktable = worktable;
  }

  ADD_WORKTABLE(worktable: Worktables.Worktable) {
    // eslint-disable-next-line max-len
    const is_worktable_exist = this.state.worktables.some((existing_worktable) => existing_worktable.id === worktable.id);

    if (!is_worktable_exist) {
      this.state.worktables.push(worktable);
    }
  }

  DELETE_WORKTABLE(worktable: Worktables.Worktable) {
    const idx = this.state.worktables.findIndex((el) => el.id === worktable.id);

    if (idx !== -1) {
      this.state.worktables.splice(idx, 1);
    }
  }

  EDIT_WORKTABLE(worktable: Worktables.Worktable) {
    const idx = this.state.worktables.findIndex((el) => el.id === worktable.id);

    Object.assign(this.state.worktables[idx], worktable);

    // this.state.worktables[idx] = worktable;
  }

  DELETE_ATTRACTION_IN_WORKTABLES(attraction: Attractions.Attraction) {
    this.state.worktables.forEach((el) => {
      if (el.attractions) {
        const idx = el.attractions.findIndex((attr) => attr.id === attraction.id);

        if (idx !== -1) {
          el.attractions.splice(idx, 1);
        }
      }

      const del_attraction = this.state.current_worktable.attractions?.find((el) => el.id === attraction.id);

      this.state.current_worktable.attractions = this.state.current_worktable.attractions?.filter((attraction) => attraction.id !== del_attraction?.id);
    });
  }

  UPDATE_WORKTABLE_TIMESLOT(timeslot: TimeslotSharedType) {
    if (!this.state.current_worktable.attractions) {
      return;
    }

    const attraction_idx = this.state.current_worktable.attractions
      .findIndex((el) => el.id === timeslot.attraction_id);

    if (attraction_idx !== -1) {
      const timeslot_idx = this.state.current_worktable.attractions[attraction_idx].timeslots
        .findIndex((el) => el.id === timeslot.id);

      if (timeslot_idx !== -1) {
        Object.assign(this.state.current_worktable.attractions[attraction_idx].timeslots[timeslot_idx], timeslot);
      }
    }
  }

  EDIT_CURRENT_WORKTABLE(worktable: Worktables.Worktable) {
    if (this.state.current_worktable.id !== worktable.id) {
      return;
    }

    this.state.current_worktable = worktable;
  }

  UPD_CURRENT_WORKTABLE_ATTRACTION(attraction: Attractions.Attraction) {
    const idx: any = this.state.current_worktable.attractions?.findIndex((el) => el.id === attraction.id);

    if (idx !== -1 && this.state.current_worktable.attractions) {
      Object.assign(this.state.current_worktable.attractions[idx], attraction);
    }
  }
}
