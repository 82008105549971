import Vue from 'vue';
import VueRouter from 'vue-router';
import { Component } from 'vue-property-decorator';
import middleware_pipeline from '@/middleware';
import auth from '@/middleware/pipes/auth';
import routes from './routes';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
  'beforeRouteLeave',
]);

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition
        || to.meta?.scrollPos
        || { top: 0, left: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const default_middlewares = [auth];

  const middleware = to?.meta?.middleware?.length ? to.meta.middleware : default_middlewares;

  const context = {
    to,
    from,
    next,
    next_middleware: next,
  };

  return middleware[0]({
    ...context,
    next_middleware: middleware_pipeline(context, middleware, 1),
  });
});

export default router;
