
import {
  Component,
  Mixins,
  Ref,
} from 'vue-property-decorator';
import { VForm } from '@/types';
import SimpleDialog from '@/components/dialogs/simple/SimpleDialog.vue';
import { ValidateMixin } from '@/mixins';
import { TextArea } from '@/components';

@Component({
  components: {
    SimpleDialog,
    TextArea,
  },
})
export default class CancelOrderOfTimeslotDialog extends Mixins(ValidateMixin) {
  @Ref()
  private readonly form!: VForm;

  message = '';

  form_valid = false;

  destroy() {
    this.form.reset();
  }

  submit() {
    if (this.form.validate()) {
      this.$emit('submit', this.message);
    }
  }
}
