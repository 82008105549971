
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class PanelsItem extends Vue {
  @Prop({ type: String, default: 'title' })
  title!: string;

  @Prop({ type: Number, default: null })
  counter!: number;
}
