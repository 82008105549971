import { Actions } from 'vuex-smart-module';
import { ErrorHandlereMixin } from '@/mixins';
import { Users } from '@/modules/users/types';
import { AuthService } from '../services';
import { Auth } from '../types';
import AuthGetters from './getters';
import AuthMutations from './mutations';
import AuthState from './state';

const authService = new AuthService();
const errorHandlereMixin = new ErrorHandlereMixin();

export default class AuthActions extends Actions<
  AuthState,
  AuthGetters,
  AuthMutations,
  AuthActions
> {
  set_token(token: string) {
    this.mutations.SET_TOKEN(token);
    localStorage.setItem(Auth.TokenPrefix.JWT, token);

    /**
     * Todo: переписать после фикса истечения токена на бэке
     * Проверяем есть ли время истечения токена в локал сторе
     * Устанавливаем интервал на раз в секунду и переписываем стор
     * Если время вышло за рамки, обновляем токен
     */
    // if (!localStorage.getItem(Auth.TokenPrefix.JWT_LIFE_TIME)) {
    //   localStorage.setItem(Auth.TokenPrefix.JWT_LIFE_TIME, String(0));
    // }
    // const interval = setInterval(() => {
    //   const current_time = localStorage.getItem(Auth.TokenPrefix.JWT_LIFE_TIME);

    //   if (current_time && process.env.VUE_APP_TOKEN_LIFE_TIME) {
    //     localStorage.setItem(Auth.TokenPrefix.JWT_LIFE_TIME, String(parseInt(current_time, 10) + 1000));

    //     if (parseInt(current_time, 10) >= parseInt(process.env.VUE_APP_TOKEN_LIFE_TIME, 10) * 1000) {
    //       this.dispatch('refresh_token');
    //     }
    //   }
    // }, 1000);
  }

  del_token() {
    this.mutations.DEL_TOKEN();
  }

  async refresh_token() {
    try {
      const token = await authService.refresh_token(this.state.token);

      localStorage.setItem(Auth.TokenPrefix.JWT_LIFE_TIME, String(0));
      this.mutations.SET_TOKEN(token);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  set_user_data(data: Users.User) {
    try {
      this.mutations.SET_USER_DATA(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async login(payload: Auth.Login) {
    const data = await authService.login(payload);

    this.dispatch('set_user_data', data.user);
    this.dispatch('set_token', data.authorization.token);
  }

  async get_user_data() {
    const data = await authService.get_user_data();

    this.actions.set_user_data(data);
  }

  async logout() {
    try {
      const { token } = this.state;

      await authService.logout(token);
      this.dispatch('del_token');
      localStorage.removeItem(Auth.TokenPrefix.JWT);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }
}
