/**
 * Статусы бэкенда
 */
export namespace ResponseStatuses {
  export enum Success {
    OK = 200,
    CREATED = 201,
    UPDATED = 202,
    NO_CONTENT = 204,
  }

  export enum Fail {
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
  }

  export enum Error {
    INTERVAL_SERVER_ERROR = 500
  }
}
