import axios, { AxiosInstance } from 'axios';
import { Auth } from '@/modules/auth/types';
import { ResponseStatuses } from '@/enums';
import { global_store } from '@/store';

export class HttpService {
  /**
   * HTTP client for API
   * @type { AxiosInstance }
   */
  protected readonly http_client!: AxiosInstance;

  private get token() {
    return localStorage.getItem(Auth.TokenPrefix.JWT);
  }

  protected apiUrl(): string {
    if (window.location.href.indexOf('localhost') >= 0) {
      return `${process.env.VUE_APP_API_LOCAL_URL}/`;
    }
    return `${process.env.VUE_APP_API_URL}/` || '';
  }

  constructor() {
    this.http_client = axios.create();

    this.http_client.interceptors.request.use((req) => {
      const r = req;

      r.baseURL = this.apiUrl();
      r.timeout = 30000;

      if (r.headers && this.token) {
        r.headers.Authorization = `Bearer ${this.token}`;
      }

      return r;
    }, (err) => Promise.reject(err));

    this.http_client.interceptors.response.use((res) => res, (err) => {
      if (err.response.status === ResponseStatuses.Fail.UNAUTHORIZED) {
        // TODO: придумать как выпилить и сделать через router + vuex !!!1
        localStorage.removeItem(Auth.TokenPrefix.JWT);
        window.location.replace(Auth.Pages.SIGN_IN);
      }

      if (err.response.status === ResponseStatuses.Fail.NOT_FOUND) {
        global_store.actions.show_snackbar({ message: 'Элемент не найден', color: 'red', icon: 'mdi-alert-circle-outline' });
      }

      return Promise.reject(err.response);
    });
  }
}
