
import {
  Component, Prop, Mixins, Emit,
} from 'vue-property-decorator';
import { ErrorHandlereMixin } from '@/mixins';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { timeslots_store } from '@/store';

@Component
export default class TimeslotBlockedMenu extends Mixins(ErrorHandlereMixin) {
  @Prop()
  timeslot!: TimeslotSharedType;

  @Emit()
  edit_comment() {
    return true;
  }

  get blocked_mesage_of_timeslot() {
    return this.timeslot?.comments[0]?.comment || '';
  }

  async unblock() {
    try {
      await timeslots_store.actions.unblock(this.timeslot);
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
