import { RouteConfig } from 'vue-router';
import auth from '@/modules/auth/routes';
import worktables from '@/modules/worktables/router';
import attractions from '@/modules/attractions/router';
import statistics from '@/modules/statistics/routes';
import users from '@/modules/users/routes';

const routes: Array<RouteConfig> = [
  ...worktables,
  ...auth,
  ...attractions,
  ...statistics,
  ...users,
  { path: '*', component: () => import(/* webpackChunkName: "page-not-found" */ '@/layouts/components/PageNotFound.vue') },
];

export default routes;
