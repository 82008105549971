
import { ClientsSharedType } from '@/modules/clients/shared/types';
import { OrdersSharedType } from '@/modules/orders/shared/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class HeaderSearchListItem extends Vue {
  @Prop()
  item!: ClientsSharedType & OrdersSharedType;

  @Prop()
  title!: string;

  @Prop({ default: null })
  id!: number;

  @Prop()
  is_order!: boolean;

  get result() {
    if (this.is_order) {
      const data = this.item as OrdersSharedType;

      return data.buyer;
    }
    return this.item as ClientsSharedType;
  }

  get full_name() {
    return `${this.result.firstname} ${this.result.surname} ${this.result.middlename}`;
  }
}
