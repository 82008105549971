import { Mutations } from 'vuex-smart-module';
import { Instructor } from '../types';
import InstructorsState from './state';

export default class InstructorsMutations extends Mutations<InstructorsState> {
  SET_INSTRUCTORS(payload: Instructor[]) {
    this.state.instructors = payload;
  }

  ADD_INSTRUCTOR(payload: Instructor) {
    this.state.instructors.push(payload);
  }

  CLEAR() {
    this.state.instructors = [];
  }
}
