export enum ClientStatuses {
  NEED_ACTION = 'need_action',
  ACTIVE = 'active',
  NOT_ACTIVE = 'no_active'
}

export const ClientStatusesTranslate: Record<string, string> = {
  [ClientStatuses.NEED_ACTION]: 'Нуждается в действии кассира',
  [ClientStatuses.ACTIVE]: 'Активен',
  [ClientStatuses.NOT_ACTIVE]: 'Неактивен',
};

export const client_statuses = Object.keys(
  ClientStatusesTranslate,
).map(
  (f: string) => ({ text: ClientStatusesTranslate[f], value: f }),
);
