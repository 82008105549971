
import { Component, Vue } from 'vue-property-decorator';
import { global_store } from '@/store';
import ToeTagClientsTableShared from '@/modules/clients/shared/components/ToeTagClientsTableShared.vue';

const global_state = global_store.state;

@Component({
  components: {
    ToeTagClientsTableShared,
  },
})
export default class Sidebar extends Vue {
  get is_mini_sidebar() {
    return global_state.is_mini_sidebar;
  }

  get sidebar_width() {
    return global_state.sidebar_width;
  }

  get sidebar_mini_width() {
    return global_state.sidebar_mini_width;
  }

  toggle_sidebar(value: boolean) {
    global_store.mutations.SIDEBAR_TOGGLE(value);
  }
}
