import { HttpService } from '@/services/http-service';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { del_mask } from '@/utils';
import { OrdersSharedType } from '@/modules/orders/shared/types';
import { CommentDTO } from '@/types';
import { Clients } from '../types';

export class ClientsService extends HttpService {
  private readonly base_path = 'clients';

  async link_toetag_with_client(lid: number, client_id: number): Promise<Clients.Client> {
    const { data } = await this.http_client.patch(`${this.base_path}/toetag/${lid}/link/${client_id}`);

    return data.data;
  }

  async search_client(search_string: string): Promise<Clients.Client[]> {
    const { data } = await this.http_client.get(this.base_path, {
      params: {
        search: search_string,
      },
    });

    return data.data;
  }

  async create_client(payload: Clients.Client): Promise<Clients.Client> {
    const { data } = await this.http_client.post(this.base_path, payload);

    return data.data;
  }

  async get_matches_clients(client: Pick<Clients.Client, 'email' | 'phone' | 'surname'>): Promise<Clients.Client []> {
    const { data } = await this.http_client.get('/search/new-client', {
      params: {
        ...client,
      },
    });

    return data.data.clients;
  }

  async get_client_by_id(client_id: number): Promise<Clients.Client> {
    const { data } = await this.http_client.get(`${this.base_path}/${client_id}`);

    return data.data;
  }

  async delete_client(client: Clients.Client): Promise<void> {
    await this.http_client.delete(`${this.base_path}/${client.id}`, {
      data: client,
    });
  }

  async update_client(client: Clients.Client): Promise<Clients.Client> {
    const payload = client;

    payload.phone = del_mask(payload.phone);

    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}`, payload);

    return data.data;
  }

  async send_comment(payload: CommentDTO): Promise<Clients.Client> {
    const { data } = await this.http_client.post(`${this.base_path}/${payload.id}/comment`, {
      comment: payload.comment,
    });

    return data.data;
  }

  async get_toe_tag_clients(search_string?: string): Promise<Clients.ToeTag[]> {
    const { data } = await this.http_client.get(`${this.base_path}/toetag`, {
      params: {
        search: search_string || null,
      },
    });

    return data.data;
  }

  async get_toe_tag_client(toetag_id: number): Promise<Clients.ToeTag> {
    const { data } = await this.http_client.get(`${this.base_path}/toetag/${toetag_id}`);

    return data.data;
  }

  async get_client_tickets(query: Clients.TicketsFilter): Promise<TicketsSharedType[]> {
    const { data } = await this.http_client.get(`${this.base_path}/${query.id}/tickets`, {
      params: query,
    });

    return data.data.data;
  }

  async get_client_orders(client_id: number): Promise<OrdersSharedType[]> {
    const { data } = await this.http_client.get(`${this.base_path}/${client_id}/orders`);

    return data.data.data;
  }
}
