import { Response } from '@/types';
import { HttpService } from '@/services/http-service';
import { ErrorHandlereMixin } from '@/mixins';
import { Users } from '@/modules/users/types';
import { Auth } from '../types';

const errorHandlerMixin = new ErrorHandlereMixin();

type LoginResponse = { status: boolean, user: Users.User, authorization: { token: string } };

export const default_user = (): Users.User => ({
  id: null,
  name: '',
  login: '',
  is_active: false,
  roles: [],
});

export class AuthService extends HttpService {
  async login(payload: Auth.Login): Promise<LoginResponse> {
    const { data } = await this.http_client.post<LoginResponse>('login', payload);

    return data;
  }

  async logout(token: string): Promise<void> {
    try {
      await this.http_client.post<Response<Auth.Token>>('logout', token);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }
  }

  async get_user_data(): Promise<Users.User> {
    const { data } = await this.http_client.get('me');

    return data.user;
  }

  async refresh_token(token: string): Promise<string> {
    const { data } = await this.http_client.post('refresh', token);

    return data.authorisation.token;
  }
}
