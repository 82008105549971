import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { Mutations } from 'vuex-smart-module';
import { Attractions } from '../types';
import AttractionsState from './state';

export default class AttractionsMutations extends Mutations<AttractionsState> {
  SET_ATTRACTIONS(payload: Attractions.Attraction[]) {
    this.state.attractions = payload;
  }

  SET_ATTRACTIONS_LIST(payload: Attractions.Attraction[]) {
    this.state.attractions_list = payload;
  }

  SET_CURRENT_ATTRACTION(payload: Attractions.Attraction) {
    this.state.current_attraction = payload;
  }

  TOGGLE_ATTRACTIONS_EXPAND() {
    this.state.attractions_expand = !this.state.attractions_expand;
  }

  ADD_ATTRACTION(payload: Attractions.Attraction) {
    // eslint-disable-next-line max-len
    const is_attraction_exist = this.state.attractions.some((existing_attraction) => existing_attraction.id === payload.id);

    if (!is_attraction_exist) {
      this.state.attractions.push(payload);
    }
  }

  DELETE_ATTRACTION(payload: Attractions.Attraction) {
    const idx = this.state.attractions.findIndex((el) => el.id === payload.id);

    if (idx !== -1) {
      this.state.attractions.splice(idx, 1);
    }
  }

  UPDATE_ATTRACTION(attraction: Attractions.Attraction) {
    const idx = this.state.attractions.findIndex((el) => el.id === attraction.id);

    if (idx !== -1) {
      Object.assign(this.state.attractions[idx], attraction);
    }
  }

  UPDATE_ATTRACTION_TIMESLOT(timeslot: TimeslotSharedType) {
    const attraction_idx = this.state.attractions.findIndex((el) => el.id === timeslot.attraction_id);

    if (attraction_idx !== -1) {
      const timeslot_idx = this.state.attractions[attraction_idx].timeslots.findIndex((el) => el.id === timeslot.id);

      if (timeslot_idx !== -1) {
        Object.assign(this.state.attractions[attraction_idx].timeslots[timeslot_idx], timeslot);
      }
    }
  }
}
