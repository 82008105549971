
import {
  Component,
  Mixins,
  Prop,
  Emit,
  Ref,
} from 'vue-property-decorator';
import { ValidateMixin } from '@/mixins';
import { TextField } from '@/components';
import { VForm } from '@/types';
import { Attractions } from '../types';

@Component({
  components: {
    TextField,
  },
})
export default class EditAttractionDialog extends Mixins(ValidateMixin) {
  @Ref()
  readonly form!: VForm;

  @Prop({})
  item!: Attractions.Attraction;

  min_age = this.item.min_age_current || this.item.min_age;
  min_weight = this.item.min_weight_current || this.item.min_weight;
  max_weight = this.item.max_weight_current || this.item.max_weight;
  form_valid = false;

  @Emit()
  close() {
    return false;
  }

  params_changed() {
    if (this.form.validate()) {
      this.$emit('params_changed', Object.assign(this.item, {
        min_age_current: this.min_age,
        min_weight_current: this.min_weight,
        max_weight_current: this.max_weight,
      }));
    }
  }
}
