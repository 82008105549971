
import { Component, Prop, Vue } from 'vue-property-decorator';
import { convert_string } from '@/utils';

@Component
export default class AvatarComponent extends Vue {
  @Prop({ type: String, default: '' })
  original_initials!: string;

  get default_avatar() {
    if (this.original_initials.length) {
      let [
        name,
        middlename,
      ] = this.original_initials.split(' ');

      if (!name) {
        name = ' ';
      }

      if (!middlename) {
        middlename = ' ';
      }

      return `${name[0]}${middlename[0]}`.trim();
    }
    return this.original_initials;
  }

  get hsl_color() {
    return convert_string(this.default_avatar);
  }
}
