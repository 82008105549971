import { HttpService } from '@/services/http-service';
import { statistics_store } from '@/store';
import { Statistics } from '../types';
import StatisticsMutations from '../store/mutations';

export class StatisticsService extends HttpService {
  private readonly base_path = 'statistics';

  async get_statistics_by_worktable_id(query: Statistics.StatisticsQuery): Promise<Statistics.Dashboard> {
    const { data } = await this.http_client.get(`${this.base_path}/dashboard`, {
      params: query,
    });

    return data.data;
  }

  async get_tickets_history_by_worktable_id(query: Statistics.StatisticsQuery): Promise<Statistics.History> {
    const { data } = await this.http_client.get(`${this.base_path}/history`, {
      params: query,
    });

    return data.data;
  }

  async get_printed_history(query: Statistics.StatisticsSortQuery) {
    try {
      const response = await this.http_client.get(`${this.base_path}/print`, {
        params: query,
        responseType: 'arraybuffer',
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const newWindow = window.open();

      if (newWindow) {
        newWindow.location.href = url;
      } else {
        throw new Error('Could not open new window.');
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async get_filtered_history(query: Statistics.StatisticsSortQuery): Promise<Statistics.History> {
    const { data } = await this.http_client.get(`${this.base_path}/history`, {
      params: query,
    });

    return data.data;
  }
}
