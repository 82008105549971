import { RouteConfig } from 'vue-router';
import { Layouts } from '@/layouts/types';
import { auth_store } from '@/store';
import { uninitializedSocket } from '@/utils/sockets/socket';
import { Auth } from './types';

const routes: RouteConfig[] = [
  {
    path: `/${Auth.Pages.SIGN_IN}`,
    name: Auth.Pages.SIGN_IN,
    component: () => import(/* webpackChunkName: "sign-in" */ './views/SignIn.vue'),
    meta: {
      layout: Layouts.SIGN_IN,
    },
  },
  {
    path: `/${Auth.Pages.LOGOUT}`,
    name: Auth.Pages.LOGOUT,
    component: {
      beforeRouteEnter(to: any, from: any, next: (arg0: { name: Auth.Pages; }) => void) {
        auth_store.actions.logout();

        const sockets_channel = window.Echo.channel(`manifest_database_user.${auth_store.state.user.id}`);

        sockets_channel.unsubscribe();

        uninitializedSocket();
        next({ name: Auth.Pages.SIGN_IN });
      },
    },
  },
];

export default routes;
