
import {
  Component,
  Mixins,
  Prop,
  Emit,
  Ref,
} from 'vue-property-decorator';
import { ValidateMixin } from '@/mixins';
import SimpleDialog from '@/components/dialogs/simple/SimpleDialog.vue';
import { TextField, TextArea, Table } from '@/components';
import { VForm } from '@/types';
import { Timeslots } from '../../types';

@Component({
  components: {
    SimpleDialog,
    TextField,
    TextArea,
    Table,
  },
})
export default class UnlockTimeslotsDialog extends Mixins(ValidateMixin) {
  @Ref()
  readonly form!: VForm;

  @Prop()
  attraction_name!: string;

  @Prop({})
  timeslots!: Timeslots.Timeslot[];

  @Emit()
  close() {
    return false;
  }

  @Emit()
  unlock(timeslot: Timeslots.Timeslot) {
    return timeslot;
  }

  headers = [
    {
      text: '',
      value: 'number',
    },
    {
      text: 'Время',
      value: 'time',
    },
    {
      text: 'Действие',
      value: 'action',
      align: 'end',
    },
  ]

  comment = '';
  form_valid = false;

  get table_height() {
    return `${this.timeslots.length * 48 > 320 ? 320 : (this.timeslots.length * 48) + 37}px`;
  }
}
