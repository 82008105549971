
import {
  Component,
  Emit,
  Mixins,
  Prop,
  Watch,
} from 'vue-property-decorator';
import {
  worktables_store, attractions_store, auth_store, timeslots_store, global_store,
} from '@/store';
import { Table } from '@/components';
import { AttractionShared, TimeslotSharedTableCols } from '@/modules/attractions/shared/types';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import {
  OrderStatuses,
  TimeslotStatuses,
  is_reserved,
  is_blocked,
} from '@/enums';
import { ClientsSharedType } from '@/modules/clients/shared/types';
import { ErrorHandlereMixin } from '@/mixins';
import moment from 'moment';
import TimeslotBlockedDialog from '@/modules/timeslots/components/TimeslotBlockedDialog.vue';
import TimeslotBlockedMenu from '@/modules/timeslots/components/TimeslotBlockedMenu.vue';
import { Timeslots } from '../../types';
import TimeslotExpandArea from '../../components/TimeslotExpandArea.vue';
import TimeslotContextMenu from '../../components/TimeslotContextMenu.vue';
import UnlockTimeslotsDialog from './UnlockTimeslotsDialog.vue';
import LockTimeslotsDialog from './LockTimeslotsDialog.vue';
import Timeslot = Timeslots.Timeslot;

const {
  actions, getters, state, mutations,
} = timeslots_store;

@Component({
  methods: { is_blocked, is_reserved },
  components: {
    LockTimeslotsDialog,
    UnlockTimeslotsDialog,
    TimeslotBlockedDialog,
    TimeslotBlockedMenu,
    TimeslotContextMenu,
    TimeslotExpandArea,
    Table,
  },
})
export default class TimeslotsTable extends Mixins(ErrorHandlereMixin) {
  created() {
    window.Echo.channel('manifest_database_main-1').listen('TimeslotUpdatedEvent', (
      data: {
        socket: null,
        timeslot: Timeslots.Timeslot
      },
    ) => {
      const { timeslot } = data;

      if (timeslot) {
        console.log('TimeslotUpdatedEvent - TimeslotsTable.vue', timeslot);
        attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(timeslot);
        worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(timeslot);
      }
    });
  }

  @Prop()
  is_locking!: boolean;

  @Prop({ type: Array, default: () => ([]) })
  items!: Timeslots.Timeslot[];

  @Prop()
  headers!: TimeslotSharedTableCols[];

  @Prop()
  attraction!: AttractionShared;

  timeslot_dialog_open = false;
  opened_timeslot!: Timeslots.Timeslot;
  selectedItems = [] as unknown as Timeslots.Timeslot[];
  start_selection_from: number | null = null;
  lock_timeslot_dialog = false;
  unlock_timeslot_dialog = false;
  active_timeslot: Timeslots.Timeslot | null = null;
  show_full_timeslot: number | null = null;

  headers_map = this.headers.filter((el) => el.selected);

  dash = '<span class="primary--text text--darken-5">—</span>';

  context_menu_options = {
    show: false,
    offset_x: 0,
    offset_y: 0,
  }

  block_menu_options = {
    show: false,
  }

  today: string = moment(new Date()).format('YYYY-MM-DD');

  @Emit()
  unlock() {
    return false;
  }

  @Watch('timeslot_for_order')
  open_timeslot_for_order() {
    if (this.timeslot_for_order) {
      this.open_timeslot(this.timeslot_for_order);
      mutations.REMOVE_TIMESLOT_FOR_ORDER();
    }
  }

  @Watch('editable_timeslot')
  async update_editable_timeslot() {
    if (this.attraction.id === this.editable_timeslot?.attraction_id) {
      if (this.opened_timeslot && this.editable_timeslot) {
        const timeslot = { ...this.opened_timeslot, tickets: [] };

        await this.open_timeslot(this.editable_timeslot);
        mutations.REMOVE_TIMESLOT_FROM_ACTIVES(timeslot);
        worktables_store.dispatch('update_worktable_timeslot', timeslot);
      }
    }
  }

  @Watch('is_locking')
  cleare_selection_timeslots() {
    this.selectedItems = [];
  }

  get user_id() {
    return auth_store.state.user.id;
  }

  get momentDate() {
    return global_store.state.date;
  }

  get time() {
    return global_store.state.time;
  }

  get locked_timeslots() {
    return this.items.filter((timeslot) => timeslot.status === TimeslotStatuses.BLOCKED);
  }

  get timeslot_statuses() {
    return TimeslotStatuses;
  }

  get expanded() {
    return attractions_store.state.attractions_expand;
  }

  get timeslot_for_order() {
    return state.timeslot_for_order;
  }

  get editable_timeslot() {
    return state.editable_timeslot;
  }

  get headers_expand() {
    return this.headers_map.filter((el) => (this.expanded ? el : el.visible));
  }

  get headers_values() {
    return this.headers_expand.map((header) => header.value);
  }

  get can_lock_timeslots() {
    return this.selectedItems.length && this.is_locking;
  }

  get can_unlock_timeslots() {
    return this.locked_timeslots.length && this.is_locking;
  }

  is_active_timeslot(timeslot: Timeslots.Timeslot) {
    return !!getters.get_active_timeslot_by_id(timeslot.id);
  }

  get current_timeslot() {
    if (this.opened_timeslot) {
      return getters.get_active_timeslot_by_id(this.opened_timeslot.id) as Timeslots.Timeslot;
    }

    return {};
  }

  // показатель есть ли у таймслота действующий заказ
  has_active_order(timeslot: Timeslots.Timeslot) {
    if (timeslot.tickets.length === 0 || !timeslot.code) {
      return false;
    }

    const index = timeslot.tickets.findIndex(
      (ticket) => ticket.order && ticket.order.status === OrderStatuses.COMPLETED,
    );

    return index !== -1;
  }

  toggle_show_full_timeslot(timeslot_id: number) {
    if (this.show_full_timeslot === timeslot_id) {
      this.show_full_timeslot = null;
    } else {
      this.show_full_timeslot = timeslot_id;
    }
  }

  get_flight_number(item: Timeslots.Timeslot) {
    return `${this.attraction.prefix}-${item.code}`;
  }

  get_client_name(timeslot: Timeslots.Timeslot): string {
    // eslint-disable-next-line no-underscore-dangle
    function _get_full_name(ticket: TicketsSharedType) {
      return ticket.client ? `${ticket.client.firstname} ${ticket.client.surname}` : 'Билет другу';
    }
    let client_list = '';

    if (timeslot.id === this.show_full_timeslot) {
      timeslot.tickets.forEach((ticket) => client_list += this.create_node(_get_full_name(ticket), 'div'));
    } else {
      client_list += this.create_node(_get_full_name(timeslot.tickets[0]), 'span');

      if (timeslot.tickets.length > 1) {
        client_list += ` (+${timeslot.tickets.length - 1})`;
      }
    }

    return client_list;
  }

  create_node(content: string, node: string): string {
    return `<${node} class="timeslot-description">${content}</${node}>`;
  }

  get_client_prop(prop_name: keyof ClientsSharedType, timeslot: Timeslots.Timeslot) {
    if (!timeslot.tickets[0]?.client) {
      return this.dash;
    }

    let client_list = '';
    let content!: ClientsSharedType[typeof prop_name];

    if (timeslot.id === this.show_full_timeslot) {
      timeslot.tickets.forEach((ticket) => {
        switch (prop_name) {
          case 'comments':
            content = ticket.comments[0]?.comment ?? this.dash;
            break;
          case 'birthday': {
            const birthday = ticket.client?.[prop_name];

            content = birthday ? moment().diff(moment(birthday), 'years') : this.dash;
            break;
          }
          default:
            content = ticket.client?.[prop_name] ?? this.dash;
        }

        client_list += this.create_node(`${content}`, 'div');
      });
    } else {
      switch (prop_name) {
        case 'comments':
          content = timeslot.tickets[0].comments[0]?.comment ?? this.dash;
          break;
        case 'birthday': {
          const birthday = timeslot.tickets[0]?.client?.[prop_name];

          content = birthday ? moment().diff(moment(birthday), 'years') : this.dash;
          break;
        }
        default:
          content = timeslot.tickets[0]?.client?.[prop_name] ?? this.dash;
      }

      client_list += this.create_node(`${content}`, 'div');
    }

    return client_list;
  }

  row_bg_color(item: Timeslots.Timeslot) {
    return this.is_time_has_passed(item) ? 'grey lighten-2' : '';
  }

  async handle_lock(comment: string, timeslots: Timeslots.Timeslot[]) {
    try {
      await actions.block_timeslots({ comment, timeslots });
      this.lock_timeslot_dialog = false;
      this.selectedItems = [];
      this.unlock();
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async handle_unlock(timeslot: Timeslots.Timeslot) {
    try {
      await timeslots_store.actions.unblock(timeslot);

      if (!this.locked_timeslots.length) {
        this.unlock_timeslot_dialog = false;
      }
    } catch (error) {
      this.handleServerError(error);
    }
  }

  start_selection(index: number) {
    if (!this.is_locking) {
      return;
    }

    this.start_selection_from = index;
    this.update_selection(index);
  }

  update_selection(index: number) {
    if (!this.start_selection_from) {
      return;
    }

    const iMin = Math.min(this.start_selection_from, index);
    const iMax = Math.max(this.start_selection_from, index);

    this.selectedItems = Array.from(
      [...this.items.slice(iMin, iMax + 1)]
        .reduce((acc, n) => acc.set(n, (acc.get(n) ?? 0) + 1), new Map()),
    )
      .filter((n) => n[1] === 1)
      .map((n) => n[0]);
  }

  stop_selection() {
    this.start_selection_from = null;
  }

  is_time_has_passed(item: Timeslots.Timeslot) {
    if (this.momentDate < this.today) {
      return true;
    }

    return this.momentDate === this.today && item.start_time < this.time;
  }

  async open_timeslot(timeslot: Timeslots.Timeslot) {
    const is_blocked = timeslot.status === TimeslotStatuses.BLOCKED;
    const is_reserved = timeslot.status === TimeslotStatuses.RESERVED;
    // временно закоментировал что бы тестить функционал недоступности открытого таймслота с одного аккаунта
    // && auth_store.state.user.id !== timeslot.edited_by;

    if (!this.timeslot_dialog_open) {
      try {
        if (!is_blocked && !is_reserved) {
          const current_timeslot: Timeslots.Timeslot = timeslot;

          current_timeslot.status = TimeslotStatuses.RESERVED;

          mutations.ADD_ACTIVE_TIMESLOT(current_timeslot);
          attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(current_timeslot);
          worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(current_timeslot);

          await actions.open_timeslot(timeslot);
          this.opened_timeslot = timeslot;
          this.timeslot_dialog_open = true;
        }
      } catch (error) {
        this.handleServerError(error);
      }
    } else {
      try {
        if (!is_blocked && !is_reserved) {
          await actions.set_timeslot(timeslot);
          this.opened_timeslot = timeslot;
          this.timeslot_dialog_open = true;
        }
      } catch (error) {
        this.handleServerError(error);
      }
    }
  }

  async timeslot_closed(item: Timeslots.Timeslot) {
    await actions.close_timeslot(item);
    this.timeslot_dialog_open = false;

    // mutations.REMOVE_TIMESLOT_FROM_ACTIVES(item);
  }

  ticket_add(item: TicketsSharedType) {
    actions.add_ticket_to_timeslot(item);
  }

  ticket_remove(ticket: TicketsSharedType) {
    actions.ticket_remove_from_timeslot(ticket);
  }

  block_menu_handler(item: Timeslots.Timeslot) {
    this.active_timeslot = item;

    this.block_menu_options.show = false;

    this.$nextTick(() => {
      this.block_menu_options.show = true;
    });
  }

  right_click_handler(event: MouseEvent, timeslot: Timeslot) {
    event.preventDefault();
    this.active_timeslot = timeslot;

    this.context_menu_options.show = false;
    this.context_menu_options.offset_x = event.clientX;
    this.context_menu_options.offset_y = event.clientY;

    this.$nextTick(() => {
      this.context_menu_options.show = true;
    });
  }

  close() {
    this.block_menu_options.show = false;
    this.context_menu_options.show = false;
  }
}
