export enum OrderStatuses {
  NEW = 'new',
  COMPLETED = 'completed',
  SAVE = 'save',
}

export enum OrderTypes {
  ONLINE = 'online',
  OFFLINE = 'offline',
  ALL = 'all'
}

export const OrderStatusesTranslate = {
  [OrderStatuses.NEW]: 'Новый',
  [OrderStatuses.COMPLETED]: 'Оплачен',
  [OrderStatuses.SAVE]: 'Сохранен',
};

export const OrderTypesTranslate = {
  [OrderTypes.OFFLINE]: 'Оффлайн',
  [OrderTypes.ONLINE]: 'Онлайн',
  [OrderTypes.ALL]: 'Все',
};
