import { ResponseStatuses } from '@/enums';
import { Actions } from 'vuex-smart-module';
// eslint-disable-next-line import/no-cycle
import { worktables_store } from '@/store';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { ErrorHandlereMixin } from '@/mixins';
import { AttractionsService } from '../services';
import { Attractions } from '../types';
import AttractionsGetters from './getters';
import AttractionsMutations from './mutations';
import AttractionsState from './state';

const attractionsService = new AttractionsService();
const errorHandlereMixin = new ErrorHandlereMixin();

export default class AttractionsActions extends Actions<
  AttractionsState,
  AttractionsGetters,
  AttractionsMutations,
  AttractionsActions
> {
  async get_attractions() {
    try {
      const data = await attractionsService.get_all();

      this.mutations.SET_ATTRACTIONS(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async set_current_attraction_by_id(id: number) {
    try {
      if (!this.state.attractions.length) {
        await this.dispatch('get_attractions');
      }

      const data = this.getters.get_by_id(id);

      if (data) {
        this.mutations.SET_CURRENT_ATTRACTION(data);
      } else {
        throw new Error('Not found');
      }
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async create_attraction(payload: Attractions.Attraction) {
    const data = await attractionsService.create_attraction(payload);

    this.mutations.ADD_ATTRACTION(payload);
  }

  async update_attraction(attraction: Attractions.Attraction) {
    const data = await attractionsService.edit_attraction(attraction);

    if (data.worktables?.length) {
      worktables_store.dispatch('get_worktables');
    }
  }

  async update_attraction_current_day_params(attraction: Attractions.Attraction) {
    const data = await attractionsService.set_current_day_params(attraction);

    this.mutations.UPDATE_ATTRACTION(data);
  }

  async delete_attraction(attraction: Attractions.Attraction) {
    const response = await attractionsService.delete_attraction(attraction);

    // if (response.status === ResponseStatuses.Success.NO_CONTENT) {
    //   this.mutations.DELETE_ATTRACTION(attraction);
    // } else {
    //   throw new Error(response.message);
    // }
  }

  update_attraction_timeslot(timeslot: TimeslotSharedType) {
    this.mutations.UPDATE_ATTRACTION_TIMESLOT(timeslot);
  }

  toggle_expand() {
    this.mutations.TOGGLE_ATTRACTIONS_EXPAND();
  }
}
