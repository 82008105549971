import { Mutations } from 'vuex-smart-module';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import TicketsState from './state';
import { Tickets } from '../types';

export default class TicketsMutations extends Mutations<TicketsState> {
  SET_TICKETS(data: Tickets.Ticket[]) {
    this.state.tickets = data;
  }

  UPDATE_TICKET(ticket: Tickets.Ticket) {
    const idx = this.state.tickets.findIndex((el) => el.id === ticket.id);

    if (idx !== -1) {
      Object.assign(this.state.tickets[idx], ticket);
    }
  }

  UPDATE_TIMESLOT_IN_TICKET(params: {ticket_id: number, timeslot: TimeslotSharedType}) {
    const {
      ticket_id,
      timeslot,
    } = params;

    const idx = this.state.tickets.findIndex((el) => el.id === ticket_id);

    if (idx !== -1) {
      const ticket = this.state.tickets[idx];

      Object.assign(ticket, { ...ticket, timeslot });
    }
  }
}
