import { AttractionTypes, AttractionStatuses } from '@/enums';
import { Attractions } from '../types';

export const default_attraction = (): Attractions.Attraction => ({
  type: AttractionTypes.SINGLE,
  status: AttractionStatuses.ACTIVE,
  bitrix_id: null,
  prefix: '',
  title: '',
  group: '',
  weight_control: false,
  booking_without_names: false,
  min_weight: null,
  max_weight: null,
  min_age: null,
  min_total_weight: undefined,
  max_total_weight: undefined,
  seats: 1,
  min_age_of_one: undefined,
  worktables: [],
  timeslots: [],
  fields: [],
});

export const default_attraction_fields = (): Attractions.TimeslotTableCols[] => ([
  {
    selected: true,
    visible: true,
    value: 'number',
    text: '№',
    description: '№ заказа',
    fixed: true,
  },
  {
    selected: true,
    visible: true,
    value: 'time',
    text: 'Время',
    fixed: true,
  },
  {
    selected: true,
    visible: true,
    value: 'clients',
    text: 'Клиенты',
  },
  {
    selected: true,
    visible: true,
    value: 'weight',
    text: 'Вес',
  },
  {
    selected: true,
    visible: false,
    value: 'count',
    text: 'Кол-во',
  },
  {
    selected: true,
    visible: false,
    value: 'sex',
    text: 'Пол',
  },
  {
    selected: true,
    visible: false,
    value: 'age',
    text: 'Возраст',
  },
  {
    selected: true,
    visible: false,
    value: 'phone',
    text: 'Номер телефона',
  },
  {
    selected: true,
    visible: false,
    value: 'email',
    text: 'E-mail',
  },
  {
    selected: true,
    visible: true,
    value: 'comment',
    text: 'Комментарий',
  },
]);
