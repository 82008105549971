import { Actions } from 'vuex-smart-module';
import { ErrorHandlereMixin } from '@/mixins';
import { TicketOrderedStatuses } from '@/enums';
import { StatisticsService } from '../services';
import { Statistics } from '../types';
import StatisticsGetters from './getters';
import StatisticsMutations from './mutations';
import StatisticsState from './state';
import { Tickets } from '../../tickets/types/index';

const statisticsService = new StatisticsService();
const errorHandlereMixin = new ErrorHandlereMixin();

export default class StatisticsActions extends Actions<
  StatisticsState,
  StatisticsGetters,
  StatisticsMutations,
  StatisticsActions
> {
  async get_statistics(query: Statistics.StatisticsQuery) {
    this.dispatch('get_dashboard', query);
    this.dispatch('get_history', query);
  }

  async get_dashboard(query: Statistics.StatisticsQuery) {
    try {
      const data = await statisticsService.get_statistics_by_worktable_id(query);

      this.mutations.SET_DASHBOARD(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async get_filtered_history(query: Statistics.StatisticsSortQuery) {
    try {
      const data = await statisticsService.get_filtered_history(query);

      this.mutations.SET_HISTORY(data);
    } catch (e) {
      errorHandlereMixin.handleServerError(e);
    }
  }

  async get_printed_history(query: Statistics.StatisticsSortQuery) {
    try {
      await statisticsService.get_printed_history(query);
    } catch (e) {
      errorHandlereMixin.handleServerError(e);
    }
  }

  async get_history(query: Statistics.StatisticsQuery) {
    try {
      const data = await statisticsService.get_tickets_history_by_worktable_id(query);

      this.mutations.SET_HISTORY(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }
}
