var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',_vm._g(_vm._b({attrs:{"max-width":"720"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',{attrs:{"outlined":"","tile":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.params_changed.apply(null, arguments)}},model:{value:(_vm.form_valid),callback:function ($$v) {_vm.form_valid=$$v},expression:"form_valid"}},[_c('v-card-title',{staticClass:"mb-5"},[_c('h3',[_vm._v("Изменение информации об ограничениях")])]),_c('v-card-subtitle',[_vm._v(" Название редактируемого аттракциона: "),_c('b',[_vm._v(_vm._s(_vm.item.title))])]),_c('v-card-text',[_c('div',{staticClass:"mb-4"},[_vm._v("Укажите минимальный и максимальный вес пассажира")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Мин.вес (кг)","type":"number","require":"","rules":[
                v => _vm.is_require(v) || _vm.require_message,
                v => !_vm.is_more_then(+v, +`${_vm.max_weight}`) || _vm.less_weight_message
              ]},model:{value:(_vm.min_weight),callback:function ($$v) {_vm.min_weight=$$v},expression:"min_weight"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Макс вес (кг)","type":"number","require":"","rules":[
                v => _vm.is_require(v) || _vm.require_message,
                v => !_vm.is_more_then(+`${_vm.min_weight}`, +v) || _vm.less_weight_message
              ]},model:{value:(_vm.max_weight),callback:function ($$v) {_vm.max_weight=$$v},expression:"max_weight"}})],1)],1),_c('div',{staticClass:"mb-4"},[_vm._v("Укажите максимально допустимый возраст пассажира")]),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('TextField',{attrs:{"label":"Возраст","type":"number","require":"","rules":[
                v => _vm.is_require(v) || _vm.require_message
              ]},model:{value:(_vm.min_age),callback:function ($$v) {_vm.min_age=$$v},expression:"min_age"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pa-5"},[_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"rounded":"","type":"submit","color":"secondary","disabled":!_vm.form_valid}},[_vm._v(" Сохранить изменения ")]),_c('v-btn',{staticClass:"pl-5 pr-5",attrs:{"text":"","rounded":""},on:{"click":_vm.close}},[_vm._v(" Отменить ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }