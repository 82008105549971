
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component({
  name: 'datepicker-component',
})
export default class DatepickerMenuComponent extends Vue {
  @Prop({ type: String, default: '' })
  date_prop!: string;

  @Prop({ type: Boolean, default: false })
  dark!: boolean;

  @Prop({ type: Boolean, default: false })
  disabled!: boolean;

  @Prop({ type: Boolean, default: false })
  small!: boolean;

  @Prop({ type: Boolean, default: false })
  reverse_icon!: boolean;

  @Watch('date_prop')
  on_date_prop_change() {
    this.$nextTick(() => {
      this.date = this.date_prop;
      this.text_date = this.format_date(this.date_prop);
    });
  }

  menu = false;
  date = this.date_prop;
  text_date: string | null = this.format_date(this.date_prop);

  get date_model() {
    return this.date;
  }

  set date_model(date) {
    this.date = date;
    this.text_date = this.format_date(date);

    this.menu = false;
    this.$emit('date_changed', this.date);
  }

  format_date(date: string) {
    if (!date) return null;

    const [
      year, month, day,
    ] = date.split('-');

    return `${day}.${month}.${year}`;
  }

  clear() {
    this.date_model = '';
    this.text_date = '';
  }
}
