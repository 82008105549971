
import { Component, Prop, Mixins } from 'vue-property-decorator';
import moment from 'moment';
import { timeslots_store, global_store } from '@/store';

import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { VForm } from '@/types';
import { ValidateMixin, PolicyMixin, ErrorHandlereMixin } from '@/mixins';
import {
  TicketCancelStatuses, TicketOrderedStatuses, TicketSaveStatuses, TicketsStatuses,
} from '@/enums';
import { Tickets } from '@/modules/tickets/types';
import { AttractionShared } from '@/modules/attractions/shared/types';
import { Clients } from '@/modules/clients/types/index';
import { Timeslots } from '../types';
import ConfimCancelOrderDialog from '../shared/components/ConfimCancelOrderDialog.vue';
import ConfimRemoveAssignDialog from '../shared/components/ConfimRemoveAssignDialog.vue';
import FindingClientsInTheManifest from './FindingClientsInTheManifest.vue';
import { ClientsService } from '../../clients/services/index';

const { actions } = timeslots_store;
const clientsService = new ClientsService();

@Component({
  components: {
    ConfimCancelOrderDialog,
    ConfimRemoveAssignDialog,
    FindingClientsInTheManifest,
  },
})
export default class TimeslotActions extends Mixins(
  ErrorHandlereMixin,
  ValidateMixin,
  PolicyMixin,
) {
  @Prop()
  private readonly form_valid!: boolean

  @Prop({ default: false })
  params!: AttractionShared;

  @Prop({ default: null })
  oldTimeSlot!: Timeslots.Timeslot;

  @Prop()
  private readonly form!: VForm;

  @Prop()
  item!: Timeslots.Timeslot

  is_loading = false;
  is_save_btn_loading = false;
  cancel_orderd_dialog = false;
  remove_assign_dialog = false;
  isTimeslotCanceled = false;
  selected_toe_tag_clients = [] as Clients.Client[];
  is_submit = false;

  get clients() {
    return this.item.tickets.map((ticket) => ticket.client);
  }

  get can_remove_assign() {
    return TicketsStatuses.BOOKING === this.tickets[0].status;
  }

  get can_cancel() {
    return this.tickets.every((ticket: TicketsSharedType) =>
      TicketCancelStatuses.includes(ticket.status));
  }

  get can_order() {
    return this.tickets.every((ticket: TicketsSharedType) =>
      TicketOrderedStatuses.includes(ticket.status));
  }

  get can_save() {
    return this.tickets.every((ticket: TicketsSharedType) =>
      TicketSaveStatuses.includes(ticket.status));
  }

  get is_today() {
    return moment().isSame(global_store.state.date, 'day');
  }

  get is_disabled_save_button() {
    return !this.tickets.every((ticket: Tickets.Ticket) => !!ticket.client.firstname);
  }

  get tickets() {
    const data = this.item.tickets;

    return data;
  }

  replace_client(client: Clients.Client, index: number) {
    this.item.tickets[index].client = client;
    this.is_submit = true;
    this.close_finding_clients();
  }

  close_finding_clients() {
    this.selected_toe_tag_clients = [];
  }

  async uncomplete() {
    try {
      await actions.uncomplete_timeslot(this.item.id);
      this.$emit('uncomplete');
      this.isTimeslotCanceled = true;
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async cancel(comment: string) {
    try {
      await actions.cancel({ timeslot: this.item, comment });
      this.cancel_orderd_dialog = false;
      this.$emit('action', 'cancel');
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async remove_assign() {
    this.remove_assign_dialog = false;
    this.is_loading = true;

    try {
      await actions.clear_timeslot(this.item);
      this.$emit('action', 'remove_assign');
    } catch (error) {
      this.handleServerError(error);
    }

    this.is_loading = false;
  }

  resetMaskPhone(phoneWithMask) {
    if (typeof phoneWithMask !== 'string') return 0;

    const regexpNumbers = /\d/g;
    const phone = phoneWithMask.match(regexpNumbers);

    if (phone) return Number(phone.join(''));
    return 0;
  }

  async submit() {
    try {
      if (this.params.booking_without_names === false && (!this.clients[0] || !this.clients[0].id)) {
        if (this.form.validate() === false) return;
        if ((this.clients[0] || this.clients[1]) && this.selected_toe_tag_clients.length === 0) {
          if (this.clients[0]) {
            this.get_clients_matches(this.clients[0]);
          }

          if (this.clients[1]) {
            this.get_clients_matches(this.clients[1]);
          }
          return;
        }
      } else {
        if (this.oldTimeSlot !== null && this.item.tickets[0].order_id) {
          try {
            await actions.reassign_timeslot({ old_timeslot: this.oldTimeSlot, new_timeslot: this.item });
          } catch (error) {
            this.handleServerError(error);
          }
        }
        this.is_loading = true;
        await actions.create_order(this.item);
        this.$emit('action', 'submit');
      }
    } catch (error) {
      this.handleServerError(error);
    } finally {
      this.is_loading = false;
    }
  }

  async get_clients_matches(client: Clients.Client) {
    const matches_clients = await clientsService.get_matches_clients({
      surname: client.surname,
      email: client.email,
      phone: this.resetMaskPhone(client.phone),
    });

    this.selected_toe_tag_clients.push(...matches_clients);
  }

  async save_timeslot() {
    try {
      if (this.params.booking_without_names === false && (!this.clients[0] || !this.clients[0].id)) {
        if (this.form.validate() === false) return;
        if ((this.clients[0] || this.clients[1]) && this.selected_toe_tag_clients.length === 0) {
          if (this.clients[0]) {
            this.get_clients_matches(this.clients[0]);
          }

          if (this.clients[1]) {
            this.get_clients_matches(this.clients[1]);
          }
          return;
        }
      } else {
        if (this.oldTimeSlot !== null && this.item.tickets[0].order_id) {
          try {
            await actions.save_reassign_timeslot({ old_timeslot: this.oldTimeSlot, new_timeslot: this.item });
          } catch (error) {
            this.handleServerError(error);
          }
        }
        this.is_save_btn_loading = true;
        await actions.save_timeslot(this.item);
        this.$emit('action', 'save_timeslot');
      }
    } catch (error) {
      this.handleServerError(error);
    } finally {
      this.is_save_btn_loading = false;
    }
  }
}
