import { SearchObject, SnackbarParams } from '@/types';

export default class GlobalState {
  sidebar_width = '300px';
  sidebar_mini_width = '24px';
  is_mini_sidebar = false;
  time = '';
  date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
  app_loading = false;
  search: SearchObject = {
    orders: [],
    clients: [],
  };

  snackbar: SnackbarParams = {
    message: '',
    color: '',
  };
}
