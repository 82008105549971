var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',_vm._g(_vm._b({attrs:{"max-width":"564"}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',[_c('v-card-title',[_c('h2',{class:[
          { [_vm.$style.titleCentered]: _vm.center_title },
          'text-h2'
        ]},[_vm._t("title")],2)]),_c('v-card-text',{class:[
        { [_vm.$style.textCentered]: _vm.center_text },
        'text-body-1'
      ]},[_vm._t("text")],2),_c('v-card-actions',{class:[_vm.$style.modalButtons, _vm.$style.center]},[_c('v-btn',{class:_vm.$style.closeSuccessDialog,attrs:{"depressed":"","color":"secondary"},on:{"click":_vm.close}},[_vm._t("btn-text",function(){return [_vm._v(" Завершить ")]})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }