import { RouteConfig } from 'vue-router';
import { PolicyMixin } from '@/mixins/index';
import router from '@/router';
import { Worktables } from './types';

const policy = new PolicyMixin();

const routes: RouteConfig[] = [
  {
    path: '/',
    name: Worktables.Pages.WORKTABLES,
    redirect: `/${Worktables.Pages.WORKTABLES}/1`,
    component: () => import(/* webpackChunkName: "worktables" */ './views/WorktablesLayout.vue'),
    children: [
      {
        path: `/${Worktables.Pages.WORKTABLES}/:id`,
        name: Worktables.Pages.WORKTABLE_DETAIL,
        component: () => import(/* webpackChunkName: "worktable-detail" */ './views/WorktableDetail.vue'),
      },
    ],
  },
  {
    path: `/${Worktables.Pages.WORKTABLES}`,
    name: Worktables.Pages.WORKTABLES_TABLE,
    component: () => import(/* webpackChunkName: "worktables-table" */ './views/WorktablesTable.vue'),
    meta: {
      hide_sidebar: true,
    },
    beforeEnter(to, from, next) {
      const access = policy.check_permissions(policy.permissions.WorktablePage.GET);

      if (!access) {
        return from.name ? false : router.replace('/');
      }
      next();
    },
  },
];

export default routes;
