import { Mutations } from 'vuex-smart-module';
import { Users } from '@/modules/users/types';
import AuthState from './state';

export default class AuthMutations extends Mutations<AuthState> {
  SET_TOKEN(token: string) {
    this.state.token = token;
  }

  DEL_TOKEN() {
    this.state.token = '';
  }

  SET_USER_DATA(payload: Users.User) {
    this.state.user = payload;
  }
}
