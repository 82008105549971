export enum TicketsTypes {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

export enum TicketsMediaPacketsTypes {
  /**
   * Проставляется системой автоматически
   * Статус отвечат за факт продажи медиа услуги клиенту (фото, видео съемка)
   */
  PRE_SALE = 'pre-sale', // Была продажа до прыжка
  AVI_SALE = 'avi-sale', // Продажа произошла после прыжка
}

export const TickestTypesTranslate = {
  [TicketsTypes.ONLINE]: 'Онлайн',
  [TicketsTypes.OFFLINE]: 'Оффлайн',
};

export const TicketsMediaPacketsTypesTranslate: Record<string, string> = {
  [TicketsMediaPacketsTypes.PRE_SALE]: 'Presale',
  [TicketsMediaPacketsTypes.AVI_SALE]: 'Avisale',
};

export const tickets_media_packets_types = Object.keys(
  TicketsMediaPacketsTypesTranslate,
).map(
  (f: string) => ({ text: TicketsMediaPacketsTypesTranslate[f], value: f }),
);
