import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { HttpService } from '@/services/http-service';
import { BaseRequestParams } from '@/types';
import { Instructor } from '@/modules/instructors/types';
import { global_store } from '@/store';
import { Attractions } from '../types';

export class AttractionsService extends HttpService {
  private readonly base_path = 'attractions';
  async get_all(): Promise<Attractions.Attraction[]> {
    const { data } = await this.http_client.get(this.base_path);

    return data.data;
  }

  async create_attraction(payload: Attractions.Attraction): Promise<Attractions.Attraction> {
    const { data } = await this.http_client.post(this.base_path, payload);

    return data.data;
  }

  async edit_attraction(payload: Attractions.Attraction): Promise<Attractions.Attraction> {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}`, payload);

    return data.data;
  }

  async delete_attraction(payload: Attractions.Attraction): Promise<any> {
    const response = await this.http_client.delete(`${this.base_path}/${payload.id}`, {
      data: payload,
    });

    return response;
  }

  async set_current_day_params(payload: Attractions.Attraction): Promise<Attractions.Attraction> {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}/current`, {
      min_age_current: payload.min_age_current,
      max_weight_current: payload.max_weight_current,
      min_weight_current: payload.min_weight_current,
    });

    return data.data;
  }

  async get_attraction_timeslots(params: BaseRequestParams): Promise<TimeslotSharedType[]> {
    const { data } = await this.http_client.get(`${this.base_path}/${params.id}/timeslots`, {
      params: {
        date: params.date,
      },
    });

    return data.data;
  }

  async connect_instructors(
    instructors: Instructor[],
    attraction_id: number,
    date: string = global_store.state.date,
  ): Promise<Attractions.Attraction> {
    const { data } = await this.http_client.patch(`${this.base_path}/${attraction_id}/instructors`, {
      instructors,
      date,
    });

    return data.data;
  }
}
