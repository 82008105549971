export enum Roles {
  ADMIN = 'admin',
  CASHIER = 'cashier',
  VIEWER = 'viewer',
}

export const RolesTranslate = {
  [Roles.ADMIN]: 'Администратор',
  [Roles.CASHIER]: 'Кассир',
  [Roles.VIEWER]: 'Наблюдатель',
};

export namespace Permissions {
  export enum AttractionPage {
    GET = 'attraction_page_get',
  }

  export enum UsersPage {
    GET = 'users_page_get',
  }

  export enum WorktablePage {
    GET = 'worktable_page_get',
  }

  export enum Worktables {
    MODIFY = 'worktables_modify',
  }

  export enum Attraction {
    MODIFY = 'attraction_modify',
  }

  export enum TimeslotsTable {
    MODIFY = 'timeslots_modify',
  }

  export enum Timeslots {
    MODIFY = 'timeslots_modify',
  }

  export enum Orders {
    MODIFY = 'orders_modify',
  }
}

export type AllPermissions =
  Permissions.AttractionPage |
  Permissions.UsersPage |
  Permissions.WorktablePage |
  Permissions.Worktables |
  Permissions.Attraction |
  Permissions.Orders |
  Permissions.TimeslotsTable |
  Permissions.Timeslots;

export const permissions_by_role: Record<Roles, AllPermissions[]> = {
  [Roles.ADMIN]: [
    Permissions.AttractionPage.GET,
    Permissions.UsersPage.GET,
    Permissions.WorktablePage.GET,
    Permissions.Worktables.MODIFY,
    Permissions.Attraction.MODIFY,
    Permissions.Orders.MODIFY,
    Permissions.TimeslotsTable.MODIFY,
    Permissions.Timeslots.MODIFY,
  ],
  [Roles.CASHIER]: [
    Permissions.Attraction.MODIFY,
    Permissions.Orders.MODIFY,
    Permissions.Timeslots.MODIFY,
  ],
  [Roles.VIEWER]: [],
};
