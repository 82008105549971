import { Mutations } from 'vuex-smart-module';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { clone_deep } from '@/utils';
import TimeslotsState from './state';
import { Timeslots } from '../types';

export default class TimeslotsMutations extends Mutations<TimeslotsState> {
  ADD_TICKET(item: TicketsSharedType) {
    const idx = this.state.active_timeslots.findIndex((el) => el.id === item.timeslot_id);

    if (idx !== -1) {
      this.state.active_timeslots[idx].tickets.push(item);
    }
  }

  SET_TIMESLOT_FOR_ORDER(timeslot: Timeslots.Timeslot) {
    this.state.timeslot_for_order = timeslot;
  }

  REMOVE_TIMESLOT_FOR_ORDER() {
    this.state.timeslot_for_order = null;
  }

  REMOVE_TICKET(item: TicketsSharedType) {
    /**
     * Передавать индекс билета для удаления, по id отрабатывает некоректно
     */
    const idx = this.state.active_timeslots.findIndex((el) => el.id === item.timeslot_id);

    if (idx !== -1) {
      const ticket_index = this.state.active_timeslots[idx].tickets.indexOf(item);

      this.state.active_timeslots[idx].tickets.splice(ticket_index, 1);
    }
  }

  ADD_ACTIVE_TIMESLOT(timeslot: Timeslots.Timeslot) {
    const idx = this.state.active_timeslots.findIndex((el) => el.id === timeslot.id);

    if (idx === -1) {
      this.state.active_timeslots.push(timeslot);
    }
  }

  REMOVE_TIMESLOT_FROM_ACTIVES(timeslot: Timeslots.Timeslot) {
    const idx = this.state.active_timeslots.findIndex((el) => el.id === timeslot.id);

    if (idx !== -1) {
      this.state.active_timeslots.splice(idx, 1);
    }
  }

  UPDATE_ACTIVE_TIMESLOT(timeslot: Timeslots.Timeslot) {
    const idx = this.state.active_timeslots.findIndex((el) => el.id === timeslot.id);

    if (idx !== -1) {
      this.state.active_timeslots[idx] = timeslot;
    }
  }

  UPDATE_EDITABLE_TIMESLOT(timeslot: Timeslots.Timeslot) {
    this.state.editable_timeslot = timeslot;
  }

  CLEAR_EDITABLE_TIMESLOT() {
    this.state.editable_timeslot = null;
  }
}
