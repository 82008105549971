import router from '@/router';
import { PolicyMixin } from '@/mixins/index';
import { RouteConfig } from 'vue-router';
import { Attractions } from './types';

const policy = new PolicyMixin();

const routes: RouteConfig[] = [
  {
    path: `/${Attractions.Pages.ATTRACTIONS}`,
    name: Attractions.Pages.ATTRACTIONS,
    component: () => import(/* webpackChunkName: "attractions" */ './views/Attractions.vue'),
    meta: {
      hide_sidebar: true,
    },
    beforeEnter(_to, from, next) {
      const access = policy.check_permissions(policy.permissions.AttractionPage.GET);

      if (!access) {
        return from.name ? false : router.replace('/');
      }
      next();
    },
  },
  {
    path: `/${Attractions.Pages.ATTRACTIONS}/new`,
    name: Attractions.Pages.ATTRACTION_NEW,
    component: () => import(/* webpackChunkName: "attraction-new" */ './views/AddNewAttraction.vue'),
    meta: {
      hide_sidebar: true,
    },
    beforeEnter(_to, from, next) {
      const access = policy.check_permissions(policy.permissions.AttractionPage.GET);

      if (!access) {
        return from.name ? false : router.replace('/');
      }
      next();
    },
  },
  {
    path: `/${Attractions.Pages.ATTRACTIONS}/:id`,
    name: Attractions.Pages.EDIT_ATTRACTION,
    component: () => import(/* webpackChunkName: "edit-attraction" */ './views/EditAttraction.vue'),
    meta: {
      hide_sidebar: true,
    },
    beforeEnter(_to, from, next) {
      const access = policy.check_permissions(policy.permissions.AttractionPage.GET);

      if (!access) {
        return from.name ? false : router.replace('/');
      }
      next();
    },
  },
  {
    path: `/${Attractions.Pages.ATTRACTIONS}/:id`,
    name: Attractions.Pages.ATTRACTION_DETAIL,
    component: () => import(/* webpackChunkName: "attraction-detail" */ './views/AttractionDetail.vue'),
    beforeEnter(_to, from, next) {
      const access = policy.check_permissions(policy.permissions.AttractionPage.GET);

      if (!access) {
        return from.name ? false : router.replace('/');
      }
      next();
    },
  },
];

export default routes;
