export enum ClientTypes {
  SKYPARK = 'skypark',
  TOETAG = 'toetag',
  OFFLINE = 'offline'
}

export const ClientTypesTranslate = {
  [ClientTypes.SKYPARK]: 'Онлайн',
  [ClientTypes.OFFLINE]: 'Оффлан',
  [ClientTypes.TOETAG]: 'Toe tag',
};
