// eslint-disable-next-line import/no-cycle
import { AttractionShared } from '@/modules/attractions/shared/types';
import { Instructor } from '@/types';

export namespace Worktables {
  export enum Pages {
    WORKTABLES = 'worktables',
    WORKTABLE_DETAIL = 'worktable',
    WORKTABLES_TABLE = 'worktables-table',
  }

  export type Worktable = {
    id: number,
    title: string,
    attractions?: AttractionShared[],
    instructors: Instructor[],
  }

  export interface AddAttractionsToWorktableRequest {
    id: number,
    attractions: number[]
  }
}
