
import { Component, Vue } from 'vue-property-decorator';
import { clients_store, auth_store } from '@/store';
import { ApplicationStatusesColors, ApplicationStatusesDescription } from '@/enums/statuses/applications';
// import io from 'socket.io-client';
import { Clients } from '../../types';
import ToeTagTableExpandArea from '../../components/toe-tag/ToeTagTableExpandArea.vue';
import FindingClientsInTheManifest from '../../components/toe-tag/FindingClientsInTheManifest.vue';

const { actions, state, mutations } = clients_store;

@Component({
  components: {
    ToeTagTableExpandArea,
    FindingClientsInTheManifest,
  },
})
export default class ToeTagClientsTableShared extends Vue {
  created() {
    window.Echo.channel('manifest_database_main-1').listen('ClientUpdatedEvent', async (
      data: {
        socket: unknown,
        client: Clients.ToeTag
      }) => {
      const { client } = data;

      if (client) {
        console.log('ClientUpdatedEvent - ', client);
        mutations.UPD_TOE_TAG_CLIENT_FROM_SOCKET(client);
      }
    });

    window.Echo.channel('manifest_database_main-1').listen('ClientCreatedEvent', async (
      data: {
        socket: unknown,
        client: Clients.ToeTag
      }) => {
      const { client } = data;

      if (client) {
        console.log('ClientCreatedEvent - ', client);
        mutations.ADD_TOE_TAG_CLIENT_FROM_SOCKET(client);
      }
    });
  }

  async mounted() {
    await actions.get_toe_tag_clients();
    this.loading = false;
  }

  loading = true;
  finding_clients_dialog = false;
  delay_before_seach_toe_tag: ReturnType<typeof setTimeout> | null = null;

  get user_id() {
    return auth_store.state.user.id;
  }

  get clients() {
    return state.toe_tag_clients;
  }

  headers = [
    {
      text: 'id',
      value: 'id',
    },
    {
      text: 'ФИО клиента',
      value: 'name',
    },
  ];

  get_item_status_color(val: keyof typeof ApplicationStatusesColors) {
    return ApplicationStatusesColors[val];
  }

  get_item_status_description(val: keyof typeof ApplicationStatusesDescription) {
    return ApplicationStatusesDescription[val];
  }

  get selected_toe_tag_client() {
    return state.selected_toe_tag_client;
  }

  search_toe_tag_clients(value: string) {
    clients_store.mutations.UPD_SEARCH_FIELD(value);

    this.loading = true;

    if (this.delay_before_seach_toe_tag) {
      clearTimeout(this.delay_before_seach_toe_tag);
    }

    this.delay_before_seach_toe_tag = setTimeout(() => {
      actions.get_toe_tag_clients(value)
        .then(() => {
          this.loading = false;
        });
    }, 500);
  }

  close_finding_clients_dialog() {
    this.finding_clients_dialog = false;
  }

  select_toe_tag_client(client: Clients.ToeTag) {
    if (this.selected_toe_tag_client?.id === client.id) {
      mutations.REMOVE_CURRENT_TOE_TAG_CLIENT();
    } else {
      this.finding_clients_dialog = true;
      mutations.SET_CURRENT_TOE_TAG_CLIENT(client);
    }
  }
}
