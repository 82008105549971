import { ResponseStatuses } from '@/enums';
import { ErrorHandlereMixin } from '@/mixins';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { Actions } from 'vuex-smart-module';
import { WorktablesService } from '../services';
import { Worktables } from '../types';
import WorktablesGetters from './getters';
import WorktablesMutations from './mutations';
import WorktablesState from './state';

const worktablesService = new WorktablesService();
const errorHandlereMixin = new ErrorHandlereMixin();

export default class WorktablesActions extends Actions<
  WorktablesState,
  WorktablesGetters,
  WorktablesMutations,
  WorktablesActions
> {
  async get_worktables() {
    try {
      const data = await worktablesService.get_worktables();

      this.mutations.SET_WORKTABLES(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async create_worktable(payload: Worktables.Worktable) {
    const data = await worktablesService.create_worktable(payload);

    // this.mutations.ADD_WORKTABLE(data);

    if (data.id) {
      return data.id;
    }
    throw new Error('No returned id');
  }

  async set_worktable_by_id(id: number) {
    try {
      const data = await worktablesService.get_worktable_by_id(id);

      this.mutations.SET_CURRENT_WORKTABLE(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async edit_worktable(worktable: Worktables.Worktable) {
    try {
      const data = await worktablesService.edit_worktable(worktable);

      this.mutations.EDIT_WORKTABLE(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async delete_worktable(worktable: Worktables.Worktable) {
    const response = await worktablesService.delete_worktable(worktable);

    if (response.status === ResponseStatuses.Success.NO_CONTENT) {
      this.mutations.DELETE_WORKTABLE(worktable);
    } else {
      throw new Error(response.message);
    }
  }

  async add_attractions_to_worktable(payload: Worktables.AddAttractionsToWorktableRequest) {
    const data = await worktablesService.add_attractions_to_worktable(payload);

    this.mutations.EDIT_WORKTABLE(data);
    this.mutations.EDIT_CURRENT_WORKTABLE(data);
  }

  update_worktables_attractions(payload: Worktables.Worktable[]) {
    payload.forEach((payload_item) => {
      const idx = this.state.worktables.findIndex((el) => el.id === payload_item.id);

      if (idx !== -1) {
        this.mutations.EDIT_WORKTABLE(payload_item);
      }
    });
  }

  update_worktable_timeslot(timeslot: TimeslotSharedType) {
    this.mutations.UPDATE_WORKTABLE_TIMESLOT(timeslot);
  }
}
