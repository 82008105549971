import { Getters } from 'vuex-smart-module';
import { Timeslots } from '@/modules/timeslots/types';
import WorktablesState from './state';

export default class WorktablesGetters extends Getters<WorktablesState> {
  get_by_id(id: number) {
    if (!this.state.current_worktable.attractions?.length) {
      return;
    }

    return this.state.current_worktable.attractions.find((attraction) => attraction.id === id);
  }

  get_timeslots_by_client_id(id: number) {
    const { attractions } = this.state.current_worktable;

    if (!attractions?.length) {
      return [];
    }

    const timeslots = [] as unknown as Timeslots.Timeslot[];

    attractions.forEach((attraction) => {
      timeslots.push(
        ...attraction.timeslots
          .filter((timeslot) => timeslot.tickets
            .find((ticket) => ticket.client?.id === id)),
      );
    });

    return timeslots;
  }
}
