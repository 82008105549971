import { HttpService } from '@/services/http-service';
import { CommentDTO } from '@/types';
import { Tickets } from '../types';

export class TicketsService extends HttpService {
  private readonly base_path = 'tickets';

  async get_tickets(): Promise<Tickets.Ticket[]> {
    return [];
  }

  async add_comment_to_ticket(payload: CommentDTO): Promise<Tickets.Ticket> {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}/comment`, {
      comment: payload.comment,
    });

    return data.data;
  }

  async assign_ticket_to_timeslot(ticket_id: number, timeslot_id: number): Promise<Tickets.Ticket> {
    const { data } = await this.http_client.patch(`${this.base_path}/${ticket_id}/timeslots/${timeslot_id}/assign`);

    return data.data;
  }

  async remove_assign_of_ticket(ticket_id: number): Promise<Tickets.Ticket> {
    const { data } = await this.http_client.patch(`${this.base_path}/${ticket_id}/remove-assign`);

    return data.data;
  }

  async uncomplete_of_ticket(ticket_id: number) {
    const { data } = await this.http_client.patch(`${this.base_path}/${ticket_id}/uncomplete`);

    return data.data;
  }

  async cancel_ticket(ticket_id: number, comment: string): Promise<Tickets.Ticket> {
    const { data } = await this.http_client.patch(`${this.base_path}/${ticket_id}/cancel`, { comment });

    return data.data;
  }

  async execute_ticket(ticket_id: number): Promise<Tickets.Ticket> {
    const { data } = await this.http_client.patch(`${this.base_path}/${ticket_id}/execute`);

    return data.data;
  }

  async revert_cancel(ticket_id: number): Promise<Tickets.Ticket> {
    const { data } = await this.http_client.patch(`${this.base_path}/${ticket_id}/renew`);

    return data.data;
  }

  async update(payload: Tickets.Ticket): Promise<Tickets.Ticket> {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}`, payload);

    return data.data;
  }
}
