
import {
  Component,
  Emit,
  Prop,
  Vue,
} from 'vue-property-decorator';
import { BtnsPosition } from './types';

@Component({
  name: 'SimpleDialog',
})
export default class SimpleDialog extends Vue {
  @Prop({ type: Boolean, default: false })
  center_title!: boolean;

  @Prop({ type: Boolean, default: false })
  center_text!: boolean;

  @Prop({ type: String, default: BtnsPosition.START })
  bts_align!: BtnsPosition;

  @Emit()
  close() {
    return false;
  }

  get is_show_title() {
    return 'title' in this.$scopedSlots;
  }

  get is_show_text() {
    return 'text' in this.$scopedSlots;
  }
}
