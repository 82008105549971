import Echo from 'laravel-echo';

let initialized = false;

export function initializeSocket() {
  if (!initialized) {
    window.io = require('socket.io-client');

    if (typeof io !== 'undefined') {
      window.Echo = new Echo({
        broadcaster: 'socket.io',
        host: process.env.VUE_APP_API_SOCKET_URL ?? 'http://localhost:6001',
      });

      window.Echo.connector.socket.on('connect', () => {
        console.log('Socket Echo connected', window.Echo.socketId());
      });
      window.Echo.connector.socket.on('disconnect', (payload: any) => {
        console.log('Socket Echo disconnected', payload);
      });
      window.Echo.connector.socket.on('reconnecting', (attempt: any) => {
        console.log('Socket Echo reconnecting', attempt);
      });

      initialized = true;
    }
  }
}

export function uninitializedSocket() {
  initialized = false;
}
