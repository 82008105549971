
import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import TimeslotsTable from '@/modules/timeslots/shared/components/TimeslotsTable.vue';
import { attractions_store, auth_store } from '@/store';
import { PolicyMixin, ErrorHandlereMixin } from '@/mixins';
import { AttractionShared } from '../types';
import EditAttractionParamsDialog from '../../components/EditAttractionParamsDialog.vue';
import { Attractions } from '../../types';
import { default_attraction_fields } from '../../constans';

@Component({
  components: {
    TimeslotsTable,
    EditAttractionParamsDialog,
  },
})
export default class AttractionCard extends Mixins(PolicyMixin, ErrorHandlereMixin) {
  created() {
    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionCreatedEvent', async (
      data: {
        socket: unknown,
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionCreatedEvent - AttractionSharedCard.vue', model);
        attractions_store.mutations.ADD_ATTRACTION(model);
      }
    });

    window.Echo.channel(`manifest_database_user.${this.user_id}`).listen('AttractionUpdatedEvent', async (
      data: {
        socket: unknown,
        model: Attractions.Attraction
      },
    ) => {
      const { model } = data;

      if (model) {
        console.log('AttractionUpdatedEvent - where is ????', model);
        attractions_store.mutations.UPDATE_ATTRACTION(model);
      }
    });
  }

  @Prop({ type: Object })
  item!: AttractionShared;

  @Prop({ type: Number })
  index!: number;

  is_params_edit_dialog = false;
  lock_timeslot = false;

  get user_id() {
    return auth_store.state.user.id;
  }

  get min_weight() {
    return this.item.min_weight_current || this.item.min_weight;
  }

  get max_weigth() {
    return this.item.max_weight_current || this.item.max_weight;
  }

  get min_age() {
    return this.item.min_age_current || this.item.min_age;
  }

  get expanded() {
    return attractions_store.state.attractions_expand;
  }

  get timeslots(): TimeslotSharedType[] {
    return this.item.timeslots;
  }

  get headers() {
    return this.item.fields?.length ? this.item.fields : default_attraction_fields();
  }

  get color() {
    switch (this.index) {
      case 0: return '#00A3E0';
      case 1: return '#FC4C02';
      case 2: return '#0047BB';
      case 3: return '#DA1984';
      case 4: return '#FC4C02';
      case 5: return '#0047BB';
      default: return '#00A3E0';
    }
  }

  expand() {
    this.$emit('expand');
  }

  async edit_params(payload: Attractions.Attraction) {
    try {
      await attractions_store.actions.update_attraction_current_day_params(payload);

      this.is_params_edit_dialog = false;
    } catch (error) {
      this.handleServerError(error);
    }
  }
}
