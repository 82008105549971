export namespace ValidationMessages {
  export enum Messages {
    REQUIRE = 'Это поле обязательно для заполнения',
    INVALID_FIELD = 'Заполните поле корректно',
    INVALID_EMAIL = 'Введите корректный email',
    INVALID_LOGIN = 'Введите корректный логин',
    INVALID_PHONE_NUMBERS = 'Номер должен состоять из 10 цифр',
    INVALID_PASSWORD_FORMAT = 'Пароль должен сожержать минимум 6 символов: 1 заглавная буква, 1 цифра и 1 символ',
    INVALID_AUTH_DATA = 'Неверный логин или пароль',
    WEIGHT_MUST_BE_LESS = 'Минимальный вес должен быть меньше максимального',
    INVALID_MAX_LENGTH = 'Превышено максимальное количество символов',
  }
}

export enum ValidationMasks {
  PHONE = '+# (###) ###-##-##-##',
  MONTH = '##.####',
  YEAR = '##.##.####',
}
