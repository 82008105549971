var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',_vm._g(_vm._b({on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.close.apply(null, arguments)},"click:outside":_vm.close}},'v-dialog',_vm.$attrs,false),_vm.$listeners),[_c('v-card',[(_vm.is_show_title)?_c('v-card-title',{staticClass:"justify-space-between pb-2 pl-4 pr-4 mb-5"},[_c('h2',{class:[
          { [_vm.$style.titleCentered]: _vm.center_title },
          'text-h2'
        ]},[_vm._t("title")],2),_c('v-btn',{attrs:{"icon":"","small":"","color":"secondary"},on:{"click":_vm.close}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1):_vm._e(),(_vm.is_show_text)?_c('v-card-text',{class:[
        { [_vm.$style.textCentered]: _vm.center_text },
        'text-body-1 mt-4 pl-4 pr-4 pb-0'
      ]},[_vm._t("text")],2):_vm._e(),_c('v-card-actions',{class:[
        _vm.$style.modalButtons,
        _vm.$style[_vm.bts_align],
      ]},[_vm._t("actions",function(){return [_c('v-btn',{class:_vm.$style.closeSuccessDialog,attrs:{"color":"secondary","depressed":""},on:{"click":_vm.close}},[_vm._v(" Завершить ")])]})],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }