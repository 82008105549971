
import {
  Component, Mixins, Emit, Prop,
} from 'vue-property-decorator';
import { ErrorHandlereMixin } from '@/mixins';
import { TextField, TextArea, Table } from '@/components';
import { SimpleDialog } from '@/components/dialogs/simple';
import { phone_format, birthdate_format } from '@/filters';
import { clients_store } from '@/store';
import { TimeslotsService } from '../services';
import { Clients } from '../../clients/types/index';

const { actions } = clients_store;

const clientsService = new TimeslotsService();

@Component({
  components: {
    TextField,
    TextArea,
    Table,
    SimpleDialog,
  },
})
export default class FindingClientsInTheManifest extends Mixins(ErrorHandlereMixin) {
  @Prop()
  client!:Clients.Client

  @Emit()
  close() {
    this.$nextTick(() => this.selected_client = null);
    return false;
  }

  @Prop()
  currentClient!:Clients.Client

  loading = false;
  selected_client = null;
  dialog = true;

  get has_linked_client() {
    return !!this.client?.application?.client_id;
  }

  set_client(client) {
    this.selected_client = client;
  }

  bind_client() {
    this.$emit('replace_client', this.selected_client);
  }

  open_client() {
    if (!this.selected_client?.id) {
      return;
    }

    try {
      this.loading = true;

      actions.set_client_by_id(this.selected_client?.id)
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    } catch (error) {
      this.handleServerError(error);
    }
  }

  bold(text, formatter) {
    let value = text;

    if (formatter) value = formatter(text);

    return `<span class="font-weight-bold text-body-1">${value}</span>`;
  }

  conver_text_to_bold(field, value) {
    if (field === 'phone') {
      const phone_string = value?.toString();

      if (phone_string && this.client[field] === value) {
        return this.bold(phone_string, phone_format);
      }

      return phone_format(phone_string);
    }

    if (field === 'birthday') {
      if (this.client[field] === value) {
        return this.bold(value, birthdate_format);
      }

      return birthdate_format(value);
    }

    return this.client[field] === value ? this.bold(value) : value;
  }

  full_name(client) {
    const firstname = this.conver_text_to_bold('firstname', client.firstname);
    const surname = this.conver_text_to_bold('surname', client.surname);
    const middlename = this.conver_text_to_bold('middlename', client.middlename ?? '');

    return `${surname} ${firstname} ${middlename}`;
  }

  birthday(client) {
    if (!client.birthday) return '-';

    return this.conver_text_to_bold('birthday', client.birthday);
  }

  email(client) {
    if (!client.email) return '-';

    return this.conver_text_to_bold('email', client.email);
  }

  phone(client) {
    if (!client.phone) return '-';

    return this.conver_text_to_bold('phone', client.phone);
  }

  async create_client() {
    try {
      this.loading = true;

      let { phone } = this.currentClient;

      if (typeof phone === 'string') {
        phone = parseInt(phone.replace(/\D/g, ''), 10);
      }

      const created_client = await clientsService.create_client({
        ...this.currentClient,
        phone,
      });

      this.$emit('replace_client', { ...created_client });
      this.show_success(`Создан новый клиент «${created_client.surname} ${created_client.firstname} ${created_client.middlename}».`);
    } catch (error) {
      this.handleServerError(error);
    } finally {
      this.loading = false;
    }
  }
}
