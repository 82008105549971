import {
  TicketsStatuses, ClientStatuses, ClientTypes, TicketsActions,
} from '@/enums';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { auth_store } from '@/store';
import { Comment } from '@/types';
import { Tickets } from '../types';

export const tickets_client_table_headers = [
  {
    text: 'Билеты',
    value: 'attraction.title',
  },
  {
    text: 'Информация',
    value: 'info',
  },
  {
    text: 'Цены',
    value: 'price',
  },
  {
    text: 'Скидка',
    value: 'discount',
    width: '200px',
  },
  {
    text: 'Бронирование',
    value: 'booked_at',
  },
  {
    text: 'Покупатель',
    value: 'client',
  },
  {
    text: 'Статус',
    value: 'status',
  },
  {
    text: 'Действия',
    value: 'actions',
  },
  {
    text: '',
    value: 'data-table-expand',
    width: '40px',
  },
];

export const tickets_order_table_headers = [
  {
    text: 'Билеты',
    value: 'attraction.title',
  },
  {
    text: 'Цены',
    value: 'price',
  },
  {
    text: 'Скидка',
    value: 'discount',
    width: '200px',
  },
  {
    text: 'Бронирование',
    value: 'booked_at',
  },
  {
    text: 'Не себе',
    value: 'myself',
  },
  {
    text: 'Владелец',
    value: 'owner',
  },
  {
    text: 'Статус',
    value: 'status',
  },
  {
    text: 'Действия',
    value: 'actions',
  },
  {
    text: '',
    value: 'data-table-expand',
    width: '40px',
  },
];

export const default_comment_by_ticket = (): Comment => ({
  commentable_id: auth_store.state.user.id as number,
  comment: '',
  commentator: auth_store.state.user,
});

export const default_ticket_by_timeslot = (item: TimeslotSharedType): Tickets.Ticket => ({
  status: TicketsStatuses.ACTIVE,
  timeslot_id: item.id,
  attraction_id: item.attraction_id,
  price: null,
  media: '',
  comments: [default_comment_by_ticket()],
  client: {
    status: ClientStatuses.ACTIVE,
    type: ClientTypes.OFFLINE,
    firstname: '',
    surname: '',
    middlename: '',
    phone: '',
    email: '',
    country: '',
    city: '',
    weight: null,
  },
});

export const permissions_by_ticket_actions: Record<TicketsActions, TicketsStatuses[]> = {
  [TicketsActions.ASSIGN]: [
    TicketsStatuses.BOOKING, TicketsStatuses.ENTRANCE,
  ],
  [TicketsActions.REMOVE_ASSIGN]: [
    TicketsStatuses.ACTIVE,
    TicketsStatuses.ENTRANCE,
  ],
  [TicketsActions.UNCOMPLETE]: [TicketsStatuses.COMPLETED],
  [TicketsActions.EXECUTE]: [TicketsStatuses.ACTIVE],
  [TicketsActions.CANCEL]: [
    TicketsStatuses.BOOKING,
    TicketsStatuses.ACTIVE,
    TicketsStatuses.ENTRANCE,
  ],
  [TicketsActions.REVERT_CANCEL]: [TicketsStatuses.CANCELED],
};
