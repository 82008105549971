
import { SelectItem } from '@/types';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Select extends Vue {
  @Prop({ type: Boolean, default: false })
  blue_icon!: boolean;

  @Prop({ type: Boolean, default: false })
  error!: boolean;

  @Prop({ type: Boolean, default: false })
  has_all_selected!: boolean;

  @Prop({ type: Array, default: () => ([]) })
  items!: SelectItem[];

  @Prop({ type: Boolean, default: false })
  require!: boolean;

  @Prop({ type: String, default: 'value' })
  item_value!: string;

  @Prop({ type: Boolean, default: false })
  return_object!: boolean;

  model_val: SelectItem[] = [];

  get icon_color() {
    return this.$attrs.value && this.$attrs.value.length > 0 ? 'indigo darken-4' : '';
  }

  get has_error() {
    if ('hide-details' in this.$attrs) {
      return !!this.$attrs['hide-details'];
    }

    if (this.error) {
      return false;
    }

    return true;
  }

  get classes() {
    return [
      'text-field',
      'rounded-0',
      { blue_icon: this.blue_icon },
      { has_error: this.has_error },
      { require: this.require },
    ];
  }

  get all_selected() {
    return this.$attrs.value?.length === this.items?.length;
  }

  get some_selected() {
    return this.$attrs.value?.length > 0 && !this.all_selected;
  }

  get icon() {
    if (this.all_selected) {
      return 'mdi-close-box';
    }
    if (this.some_selected) {
      return 'mdi-minus-box';
    }
    return 'mdi-checkbox-blank-outline';
  }

  toggle() {
    this.$nextTick(() => {
      if (this.all_selected) {
        this.$emit('input', []);
      } else {
        let items = this.items.slice();

        if (!this.return_object) {
          if (this.item_value !== 'value') {
            items = this.items.map((e) => e[this.item_value]) as SelectItem[];
          } else {
            items = this.items.map((e) => e.value) as SelectItem[];
          }
        }
        this.$emit('input', items);
      }
    });
  }
}
