import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { Actions } from 'vuex-smart-module';
import { attractions_store, worktables_store, tickets_store } from '@/store';
import { TimeslotStatuses, is_blocked } from '@/enums';
import { PolicyMixin, ErrorHandlereMixin } from '@/mixins';
import { clone_deep } from '@/utils';
import { Timeslots } from '../types';
import TimeslotsGetters from './getters';
import TimeslotsMutations from './mutations';
import TimeslotsState from './state';
import { TimeslotsService } from '../services';
import Timeslot = Timeslots.Timeslot;

const timeslotsService = new TimeslotsService();
const errorHandlerMixin = new ErrorHandlereMixin();
const policy = new PolicyMixin();

export default class TimeslotsActions extends Actions<
  TimeslotsState,
  TimeslotsGetters,
  TimeslotsMutations,
  TimeslotsActions
> {
  add_ticket_to_timeslot(item: TicketsSharedType) {
    this.mutations.ADD_TICKET(item);
  }

  ticket_remove_from_timeslot(ticket: TicketsSharedType) {
    this.mutations.REMOVE_TICKET(ticket);
  }

  set_timeslot(timeslot: Timeslots.Timeslot) {
    this.mutations.ADD_ACTIVE_TIMESLOT(timeslot);
  }

  async open_timeslot(timeslot: Timeslots.Timeslot) {
    this.mutations.ADD_ACTIVE_TIMESLOT(timeslot);
    this.dispatch('reserve', timeslot);
  }

  async close_timeslot(timeslot: Timeslots.Timeslot) {
    const actual_active_timeslot = this.getters.get_active_timeslot_by_id(timeslot.id);

    if (actual_active_timeslot) {
      actual_active_timeslot.tickets = actual_active_timeslot.tickets.filter((ticket) => ticket.id);
      await this.dispatch('remove_reserve', actual_active_timeslot);

      this.mutations.REMOVE_TIMESLOT_FOR_ORDER(actual_active_timeslot);
      // this.mutations.REMOVE_TIMESLOT_FROM_ACTIVES(actual_active_timeslot);
    } else {
      await this.dispatch('remove_reserve', timeslot);
      this.mutations.REMOVE_TIMESLOT_FOR_ORDER(timeslot);
      // this.mutations.REMOVE_TIMESLOT_FROM_ACTIVES(timeslot);
    }
  }

  async reserve(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    let result = timeslot;

    const permission = policy.check_permissions(policy.permissions.Timeslots.MODIFY);
    // const is_not_blocked = [
    //   TimeslotStatuses.RESERVED,
    //   TimeslotStatuses.BLOCKED,
    // ].indexOf(timeslot.status) === -1;

    if (timeslot) {
      try {
        result = await timeslotsService.reserve_timeslot(timeslot);
      } catch (error) {
        errorHandlerMixin.handleServerError(error);
      }
      attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(result);
    }

    this.mutations.ADD_ACTIVE_TIMESLOT(result);
    // attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(result);
    // worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(result);
    return result;
  }

  async remove_reserve(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    let result = timeslot;

    if (timeslot.status === TimeslotStatuses.RESERVED) {
      try {
        result = await timeslotsService.remove_reserve_from_timeslot(timeslot);
      } catch (error) {
        errorHandlerMixin.handleServerError(error);
      }
    }

    attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(result);
    worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(result);

    return result;
  }

  async clear_timeslot(timeslot: Timeslots.Timeslot) {
    try {
      const data = await timeslotsService.remove_assign_of_timeslot(timeslot);

      this.mutations.UPDATE_ACTIVE_TIMESLOT(data);
      worktables_store.dispatch('update_worktable_timeslot', data);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }
  }

  async create_order(timeslot: Timeslots.Timeslot) {
    try {
      const orderId = timeslot.tickets[0].order_id;
      let orderData;

      if (orderId) {
        orderData = await timeslotsService.update(orderId, timeslot);
        this.mutations.UPDATE_ACTIVE_TIMESLOT(orderData);
        worktables_store.dispatch('update_worktable_timeslot', orderData);
      }

      const executed_data = await timeslotsService.create_order(timeslot);

      setTimeout(() => {
        this.mutations.REMOVE_TIMESLOT_FROM_ACTIVES(timeslot);
      }, 1000);
      this.mutations.UPDATE_ACTIVE_TIMESLOT(executed_data);
      worktables_store.dispatch('update_worktable_timeslot', executed_data);

      tickets_store.dispatch('update_tickets', timeslot.tickets);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }
  }

  async save_timeslot(timeslot: Timeslots.Timeslot) {
    try {
      let data;

      const orderId = timeslot.tickets[0].order_id;

      if (orderId) {
        data = await timeslotsService.update(orderId, timeslot);
      } else {
        data = await timeslotsService.save(timeslot);
      }

      this.mutations.UPDATE_ACTIVE_TIMESLOT(data);
      worktables_store.dispatch('update_worktable_timeslot', data);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }
  }

  async cancel(params: { timeslot: Timeslots.Timeslot, comment: string }) {
    try {
      const data = await timeslotsService.cancel(params.timeslot.id, params.comment);

      this.mutations.UPDATE_ACTIVE_TIMESLOT(data);
      worktables_store.dispatch('update_worktable_timeslot', data);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }
  }

  async execute(timeslot: Timeslots.Timeslot) {
    try {
      const data = await timeslotsService.execute(timeslot.id);

      this.mutations.UPDATE_ACTIVE_TIMESLOT(data);
      worktables_store.dispatch('update_worktable_timeslot', data);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }
  }

  async change_timeslot(params: { old_timeslot: Timeslots.Timeslot, new_timeslot: Timeslots.Timeslot }) {
    const timeslot = {
      ...clone_deep(params.new_timeslot),
      code: params.old_timeslot.code,
      edited_by: params.old_timeslot.edited_by,
      tickets: params.old_timeslot.tickets,
    };

    this.mutations.UPDATE_EDITABLE_TIMESLOT(timeslot);
    this.mutations.ADD_ACTIVE_TIMESLOT(timeslot);
  }

  async reassign_timeslot(params: { old_timeslot: Timeslots.Timeslot, new_timeslot: Timeslots.Timeslot }) {
    await timeslotsService.reassign_timeslot(params.old_timeslot.id, params.new_timeslot.id);

    const actualTimeslot = params.old_timeslot;

    actualTimeslot.status = TimeslotStatuses.NEW;
    attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(actualTimeslot);
    worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(actualTimeslot);
    this.mutations.REMOVE_TIMESLOT_FROM_ACTIVES(actualTimeslot);
  }

  async save_reassign_timeslot(params: { old_timeslot: Timeslots.Timeslot, new_timeslot: Timeslots.Timeslot }) {
    await timeslotsService.reassign_timeslot(params.old_timeslot.id, params.new_timeslot.id);

    const { new_timeslot } = params;
    const { old_timeslot } = params;

    attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(new_timeslot);
    worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(new_timeslot);
    // this.mutations.REMOVE_TIMESLOT_FROM_ACTIVES(new_timeslot);
    attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(old_timeslot);
    worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(old_timeslot);
    // this.mutations.REMOVE_TIMESLOT_FROM_ACTIVES(old_timeslot);
  }

  async block_timeslots(params: { comment: string, timeslots: Timeslots.Timeslot[] }) {
    const timeslots = await timeslotsService.block_timeslots(params.comment, params.timeslots);

    timeslots.forEach((timeslot: Timeslot) => {
      worktables_store.dispatch('update_worktable_timeslot', timeslot);
    });
  }

  async uncomplete_timeslot(timeslot_id: number) {
    try {
      const data = await timeslotsService.uncomplete_timeslot(timeslot_id);

      this.mutations.UPDATE_ACTIVE_TIMESLOT(data);
      worktables_store.dispatch('update_worktable_timeslot', data);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }
  }

  async unblock(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    let result = timeslot;

    const permission = policy.check_permissions(policy.permissions.Timeslots.MODIFY);

    if (is_blocked(timeslot.status) && permission) {
      try {
        result = await timeslotsService.unblock_timeslot_by_id(timeslot);
      } catch (error) {
        errorHandlerMixin.handleServerError(error);
      }
      result = {
        ...timeslot,
        status: result.status,
      };

      attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(result);
    }

    this.mutations.ADD_ACTIVE_TIMESLOT(result);
    attractions_store.mutations.UPDATE_ATTRACTION_TIMESLOT(result);
    worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(result);
    return result;
  }

  async comment(params: { timeslot: Timeslots.Timeslot, payload: string }): Promise<Timeslots.Timeslot> {
    let result = params.timeslot;

    try {
      result = await timeslotsService.comment(params.timeslot, params.payload);

      worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(result);
    } catch (error) {
      errorHandlerMixin.handleServerError(error);
    }

    return result;
  }
}
