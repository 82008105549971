import { ClientStatuses, ClientTypes } from '@/enums';
import { Clients } from '../types';

export const comments_table_header = [
  {
    text: '',
    value: 'created_at',
  },
  {
    text: '',
    value: 'commentator',
  },
  {
    text: '',
    value: 'comment',
    width: '60%',
  },
];

export const client_orders_table_header = [
  {
    text: '№ заказа',
    value: 'id',
  },
  {
    text: 'Дата',
    value: 'created_at',
  },
];

export const required_fields_for_comparison = [
  'birthday',
  'city',
  'country',
  'firstname',
  'middlename',
  'phone',
  'surname',
] as unknown as keyof Clients.Client;

export const default_client = (): Clients.Client => ({
  status: ClientStatuses.ACTIVE,
  type: ClientTypes.OFFLINE,
  firstname: '',
  surname: '',
  middlename: '',
  phone: '',
  email: '',
  country: '',
  city: '',
  weight: null,
});

export const empty_online_client: Clients.Client = {
  status: ClientStatuses.ACTIVE,
  type: ClientTypes.SKYPARK,
  firstname: '',
  surname: '',
  middlename: '',
  phone: '',
  email: '',
  country: '',
  city: '',
  weight: null,
};
