/* eslint-disable no-param-reassign */
import { HttpService } from '@/services/http-service';
import { del_mask } from '@/utils';
import { Clients } from '@/modules/clients/types';
import { Timeslots } from '../types';

export class TimeslotsService extends HttpService {
  private readonly base_path = 'timeslots';

  async create_client(payload: any) {
    const { data } = await this.http_client.post('clients', payload);

    return data.data;
  }

  async create_order(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    timeslot.tickets = timeslot.tickets.map((ticket) => {
      if (ticket.client) {
        ticket.client.phone = del_mask(ticket.client.phone);
      }
      return ticket;
    });

    const { data } = await this.http_client.post(`${this.base_path}/${timeslot.id}/order`, timeslot);

    return data.data;
  }

  async remove_assign_of_timeslot(payload: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}/remove-assign`);

    return data.data;
  }

  async reassign_timeslot(old_timeslot_id: number, new_timeslot_id: number): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${old_timeslot_id}/reassign/${new_timeslot_id}`);

    return data.data;
  }

  async get_timeslot(timeslot_id: number): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.get(`${this.base_path}/${timeslot_id}`);

    return data.data;
  }

  async reserve_timeslot(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot.id}/reserve`, timeslot);

    return data.data;
  }

  async remove_reserve_from_timeslot(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot.id}/remove-reserve`, timeslot);

    return data.data;
  }

  async block_timeslots(comment: string, timeslots: Timeslots.Timeslot[]): Promise<Timeslots.Timeslot[]> {
    const { data } = await this.http_client.patch(`${this.base_path}/block`, {
      comment,
      timeslots,
    });

    return data.data;
  }

  async block_timeslot_by_id(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot.id}/block`, timeslot);

    return data.data;
  }

  async unblock_timeslot_by_id(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot.id}/unblock`, timeslot);

    return data.data;
  }

  async update_timeslot(payload: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}`, payload);

    return data.data;
  }

  async uncomplete_timeslot(timeslot_id: number): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot_id}/uncomplete`);

    return data.data;
  }

  /**
   * @param timeslot_id
   * @param comment
   * @returns Timeslot
   * Отменяет оформленный таймслот
   * Возвращает билеты и таймслот в состояние assign
   */
  async cancel(timeslot_id: number, comment: string): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot_id}/cancel`, { comment });

    return data.data;
  }

  /**
   * @param timeslot_id
   * @returns Timeslot
   * Выполняет таймслот и билеты в нем в статус "Использован"
   * Синоним действию оформить
   */
  async execute(timeslot_id: number): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot_id}/execute`);

    return data.data;
  }

  /**
   * @param timeslot
   * @returns Timeslot
   * Сохраняет данные по таймслоту (Кнопка Сохранить)
   */
  async save(timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    timeslot.tickets = timeslot.tickets.map((ticket) => {
      if (ticket.client) {
        ticket.client.phone = del_mask(ticket.client.phone);
      }
      return ticket;
    });

    const { data } = await this.http_client.patch(`${this.base_path}/${timeslot.id}/save`, timeslot);

    return data.data;
  }

  /**
   * @param orderId
   * @param timeslot
   * @returns Timeslot
   * Обновляет данные по таймслоту (Кнопка Сохранить - повторно)
   */
  async update(orderId: number, timeslot: Timeslots.Timeslot): Promise<Timeslots.Timeslot> {
    timeslot.tickets = timeslot.tickets.map((ticket) => {
      if (ticket.client) {
        ticket.client.phone = del_mask(ticket.client.phone);
      }
      return ticket;
    });

    const { data } = await this.http_client.patch(`orders/${orderId}`, timeslot);

    return data.data;
  }

  /**
   * Добавление комментария к таймслоту
   * @param timeslot
   * @param comment
   * @returns Timeslot
   */
  async comment(timeslot: Timeslots.Timeslot, comment: string): Promise<Timeslots.Timeslot> {
    const { data } = await this.http_client.post(`${this.base_path}/${timeslot.id}/comment`, {
      comment,
    });

    return data.data;
  }

  async get_matches_clients(client: Pick<Clients.Client, 'email' | 'phone' | 'surname'>): Promise<Clients.Client []> {
    const { data } = await this.http_client.get('/search/new-client', {
      params: {
        ...client,
      },
    });

    return data.data.clients;
  }
}
