import { CommentDTO } from '@/types';
import { Actions } from 'vuex-smart-module';
import { tickets_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import OrdersService from '../services';
import { Orders } from '../types';
import OrdersGetters from './getters';
import OrdersMutations from './mutations';
import OrdersState from './state';

const ordersService = new OrdersService();
const errorHandlereMixin = new ErrorHandlereMixin();

export default class OrdersActions extends Actions<
  OrdersState,
  OrdersGetters,
  OrdersMutations,
  OrdersActions
> {
  async get_order_by_id(order_id: number) {
    try {
      const data = await ordersService.get_order_by_id(order_id);

      this.mutations.SELECT_ORDER(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async create_comment(payload: CommentDTO) {
    try {
      const data = await ordersService.add_comment_to_order(payload);

      this.mutations.UPDATE_COMMNETS(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  select_order(order: Orders.Order) {
    this.mutations.SELECT_ORDER(order);
    tickets_store.mutations.SET_TICKETS(order.tickets);
  }

  close_order() {
    this.mutations.CLOSE_SELECTED_ORDER();
  }
}
