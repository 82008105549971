import MessageMixin from '@/mixins/messageMixin/messageMixin';
import { Component, Mixins } from 'vue-property-decorator';
import { ResponseStatuses, ErrorsTranslate } from '@/enums';

@Component
export default class ErrorHandlereMixin extends Mixins(MessageMixin) {
  handleServerError(error: any) {
    let status: number | null = null;
    let message = null;

    if (error.status) {
      status = error.status;
    }

    if (error.data?.message) {
      message = ErrorsTranslate[error.data.message] ?? message;
    }

    switch (status) {
      case ResponseStatuses.Fail.BAD_REQUEST:
        message = message ?? 'Запрос не корректен. Проверьте введенные данные и попробуйте еще раз';
        break;
      case ResponseStatuses.Fail.UNAUTHORIZED:
        message = message ?? 'Пожалуйста, авторизуйтесь';
        break;
      case ResponseStatuses.Fail.FORBIDDEN:
        message = message ?? 'Недостаточно прав';
        break;
      case ResponseStatuses.Fail.NOT_FOUND:
        message = message ?? 'Элемент не найден';
        break;
      case ResponseStatuses.Error.INTERVAL_SERVER_ERROR:
        message = message ?? 'Сервер не отвечает, пожалуйста, обратитесь к администратору';
        break;
      default:
        message = message ?? 'Неизвестная ошибка';
        break;
    }

    if (message) {
      this.show_error(message);
    }
  }
}
