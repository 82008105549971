import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { Mutations } from 'vuex-smart-module';
import { Clients } from '../types';
import ClientsState from './state';

export default class ClientsMutations extends Mutations<ClientsState> {
  SELECT_CLIENT(payload: Clients.Client) {
    this.state.selected_client_tickets = [];
    this.state.selected_client = payload;
  }

  CLOSE_SELECTED_CLIENT() {
    this.state.selected_client = null;
    this.state.selected_client_tickets = [];
  }

  SET_TOE_TAG_CLIENTS(clients: Clients.ToeTag[]) {
    this.state.toe_tag_clients = clients;
  }

  // Временная мутация для добавления тойтага с сервера
  SET_ONE_TOE_TAG_CLIENTS(clients: Clients.ToeTag) {
    this.state.toe_tag_clients.push(clients);
  }

  UPDATE_COMMNETS(client: Clients.Client) {
    if (this.state.selected_client) {
      this.state.selected_client.comments = client.comments;
    }
  }

  EDIT_CLIENT(client: Clients.Client) {
    if (this.state.selected_client) {
      Object.assign(this.state.selected_client, client);
    }
  }

  SET_CURRENT_CLIENT_TICKETS(tickets: TicketsSharedType[]) {
    if (this.state.selected_client) {
      this.state.selected_client_tickets = tickets;
    }
  }

  EDIT_TICKET_IN_CLIENTS_TICKETS(ticket: TicketsSharedType) {
    const ticket_idx = this.state.selected_client_tickets.findIndex((el) => el.id === ticket.id);

    if (ticket_idx !== -1) {
      Object.assign(this.state.selected_client_tickets[ticket_idx], ticket);
    }
  }

  ADD_SEARCH_CLIENTS(clients: Clients.Client[]) {
    this.state.clients = clients;
  }

  CLEAR_SEARCH_CLIENTS() {
    this.state.clients = [];
  }

  SET_CURRENT_TOE_TAG_CLIENT(client: Clients.ToeTag) {
    this.state.selected_toe_tag_client = client;
  }

  SET_MATCHES_IN_TOE_TAG_CLIENT(clients: Clients.Client[]) {
    if (this.state.selected_toe_tag_client?.matches) {
      this.state.selected_toe_tag_client.matches = clients;
    }
  }

  REMOVE_CURRENT_TOE_TAG_CLIENT() {
    this.state.selected_toe_tag_client = null;
  }

  SET_TICKET_CLIENT(client: Clients.Client) {
    this.state.selected_ticket_client = client;
  }

  REMOVE_TICKET_CLIENT() {
    this.state.selected_ticket_client = null;
  }

  ADD_TOE_TAG_CLIENT_FROM_SOCKET(client: Clients.ToeTag) {
    if (this.state.search_field.length === 0) {
      const current_client = this.state.toe_tag_clients.find((el) => el.id === client.id);

      if (!current_client) {
        this.state.toe_tag_clients.unshift(client);
      }
    }
  }

  UPD_TOE_TAG_CLIENT_FROM_SOCKET(client: Clients.ToeTag) {
    const current_client = this.state.toe_tag_clients.find((el) => el.id === client.id);

    if (current_client) {
      Object.assign(current_client, client);
    }
  }

  UPD_SEARCH_FIELD(value: string) {
    this.state.search_field = value;
  }
}
