
import { MenuItem } from '@/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DropdownMenuIcon from './DropdownMenuIcon.vue';

@Component({
  components: {
    DropdownMenuIcon,
  },
})
export default class DropdownMenu extends Vue {
  @Prop({ type: Array })
  items!: MenuItem[]

  @Prop({ type: Boolean })
  icon!: boolean

  menu_link(item: MenuItem) {
    return item?.link ? { name: item.link } : '';
  }
}
