import { Module } from 'vuex-smart-module';
import InstructorsActions from './actions';
import InstructorsGetters from './getters';
import InstructorsMutations from './mutations';
import InstructorsState from './state';

export default new Module({
  state: InstructorsState,
  mutations: InstructorsMutations,
  actions: InstructorsActions,
  getters: InstructorsGetters,
});
