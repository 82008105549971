import { Auth } from '@/modules/auth/types';
import { auth_store } from '@/store';
import { MiddlewarePayload } from '@/types';
import { Users } from '@/modules/users/types';
import load_profile from '../loaders/profile';
import load_time from '../loaders/time';

export default async function auth(payload: MiddlewarePayload) {
  const { to, next_middleware, next } = payload;
  const jwt = localStorage.getItem(Auth.TokenPrefix.JWT);

  if (!jwt) {
    if (to.name === Auth.Pages.SIGN_IN || to.name === Users.Pages.RECOVERY) {
      return next();
    }
    return next({ name: Auth.Pages.SIGN_IN });
  }

  auth_store.actions.set_token(jwt);
  await load_profile();
  await load_time();
  return next_middleware();
}
