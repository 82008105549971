import { RouteConfig } from 'vue-router';
import { PolicyMixin } from '@/mixins';
import router from '@/router';
import { Layouts } from '@/layouts/types';
import { Users } from './types';

const policy = new PolicyMixin();

const routes: RouteConfig[] = [
  {
    path: `/${Users.Pages.INDEX}`,
    name: Users.Pages.INDEX,
    component: () => import(/* webpackChunkName: "users" */ './views/UserIndex.vue'),
    meta: {
      hide_sidebar: true,
    },
    beforeEnter(_to, from, next) {
      const access = policy.check_permissions(policy.permissions.UsersPage.GET);

      if (!access) {
        return from.name ? false : router.replace('/');
      }
      next();
    },
  },
  {
    path: `/${Users.Pages.RECOVERY}`,
    name: Users.Pages.RECOVERY,
    component: () => import('./views/UserRecovery.vue'),
    meta: {
      layout: Layouts.GUEST,
    },
  },
];

export default routes;
