import { Module } from 'vuex-smart-module';
import StatisticsActions from './actions';
import StatisticsGetters from './getters';
import StatisticsMutations from './mutations';
import StatisticsState from './state';

export default new Module({
  state: StatisticsState,
  mutations: StatisticsMutations,
  actions: StatisticsActions,
  getters: StatisticsGetters,
});
