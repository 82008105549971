export enum AttractionsGroups {
  /**
   * Группы, пока что тут список, позже будет тянуться из api
   */
  GROUP_1 = 'GROUP_1',
}

export const attractions_groups = Object.keys(
  AttractionsGroups,
).map(
  (f: string) => ({ text: f, value: f }),
);
