// eslint-disable-next-line import/no-cycle
import { RolesTranslate } from '@/enums';

export namespace Users {
  export enum Pages {
    INDEX = 'users',
    RECOVERY = 'reset-password'
  }

  export type UserRole = {
    guard_name: string,
    id: number,
    name: typeof RolesTranslate,
  }

  export type User = {
    id: number | null,
    is_active: boolean,
    login: string,
    name: string,
    roles: Users.UserRole[] | [],
    created_at?: string,
    updated_at?: string,
  }

  export type PasswordReset = {
    password: string,
    password_confirmation: string,
    token: string,
    email: string,
  }
}
