import { SearchService } from '@/services/http/search-service';
import { SnackbarParams } from '@/types';
import { Actions } from 'vuex-smart-module';
import GlobalGetters from './getters';
import GlobalMutations from './mutations';
import GlobalState from './state';

const searchService = new SearchService();

export default class GlobalActions extends Actions<
  GlobalState,
  GlobalGetters,
  GlobalMutations,
  GlobalActions
> {
  get_time() {
    const today = new Date();
    const hours = today.getHours() >= 10 ? today.getHours() : `0${today.getHours()}`;
    const minutes = today.getMinutes() >= 10 ? today.getMinutes() : `0${today.getMinutes()}`;

    this.dispatch('set_time', `${hours}:${minutes}`);
  }

  set_time(val: string) {
    this.mutations.SET_TIME(val);
  }

  set_date(val: string) {
    this.mutations.SET_DATE(val);
  }

  toggle_app_loading(val: boolean) {
    this.mutations.TOGGLE_APP_LOADING(val);
  }

  clear_search() {
    this.mutations.CLEAR_SEARCH();
  }

  show_snackbar(params: SnackbarParams) {
    const default_snackbar: SnackbarParams = {
      message: '',
      color: 'green',
      icon: 'mdi-check',
    };

    const result = Object.assign(default_snackbar, params);

    this.mutations.SET_SNACKBAR(result);
  }

  async search(payload: string) {
    const data = await searchService.search(payload);

    this.mutations.SET_SEARCH(data);
  }
}
