
import { Component, Prop, Mixins } from 'vue-property-decorator';
import { timeslots_store } from '@/store';
import { ErrorHandlereMixin } from '@/mixins';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import { is_reserved, is_blocked } from '@/enums';

@Component
export default class TimeslotContextMenu extends Mixins(ErrorHandlereMixin) {
  @Prop({ default: 0 })
  position_x!: number;

  @Prop({ default: 0 })
  position_y!: number

  @Prop()
  timeslot!: TimeslotSharedType

  model = true;

  get can_reserver_timeslot() {
    return !this.is_timeslot_blocked && !this.is_timeslot_reserved;
  }

  get is_timeslot_blocked() {
    return this.timeslot && is_blocked(this.timeslot.status);
  }

  get is_timeslot_reserved() {
    return this.timeslot && is_reserved(this.timeslot.status);
  }

  async unblock() {
    try {
      await timeslots_store.actions.unblock(this.timeslot);
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async set_reserved() {
    try {
      await timeslots_store.actions.reserve(this.timeslot);
      this.$emit('reserved', this.timeslot);
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async remove_reserve() {
    try {
      await timeslots_store.actions.remove_reserve(this.timeslot);
    } catch (error) {
      this.handleServerError(error);
    }
  }

  close() {
    this.$emit('close');
  }
}
