
import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator';
import 'swiper/swiper.min.css';
/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/extensions
import { Swiper as SwiperClass } from 'swiper/types';
import AttractionSharedCard from '@/modules/attractions/shared/components/AttractionSharedCard.vue';
import { Timeslots } from '@/modules/timeslots/types';
import SliderBtn from './SliderBtn.vue';

@Component({
  components: {
    AttractionSharedCard,
    SliderBtn,
  },
})
export default class Slider extends Vue {
  @Ref()
  readonly container!: HTMLDivElement;

  @Ref()
  readonly pagination!: HTMLDivElement;

  @Prop({ type: Array, default: () => ([]) })
  slides!: unknown[];

  @Prop({ type: Array })
  timeslots!: Timeslots.Timeslot;

  @Prop({ type: Boolean, default: false })
  expanded!: boolean;

  @Prop({ type: Number, default: 0 })
  currentIndex!: number;

  @Prop({ type: Number, default: 4 })
  items_per_view_xl!: number;

  @Prop({ type: Number, default: 3 })
  items_per_view_lg!: number;

  @Prop({ type: Number, default: 2 })
  items_per_view_md!: number;

  @Prop({ type: Boolean, default: true })
  show_nav!: boolean;

  @Watch('slides')
  on_items_change() {
    this.$nextTick(() => {
      if (this.slider) {
        this.destroy_slider();
        this.init_slider();
      }
    });
  }

  @Watch('expanded')
  on_expand() {
    this.$nextTick(() => {
      if (this.slider) {
        this.index_on_change = this.currentIndex;
        // eslint-disable-next-line no-unused-expressions
        this.slider?.slideTo(this.currentIndex);
        this.destroy_slider();
        this.init_slider(this.index_on_change);
      }
    });
  }

  async mounted() {
    await this.init_slider();
  }

  beforeDestroy() {
    this.destroy_slider();
  }

  slider: SwiperClass | null = null;

  activeIndex = 0;

  index_on_change = 0;

  async init_slider(index = 0) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const SliderInst = await require('swiper/swiper-bundle.esm').default;

    if (SliderInst) {
      this.slider = new SliderInst(this.container, {
        spaceBetween: 10,
        allowTouchMove: false,
        slidesPerView: this.expanded ? 1 : 2,
        slideToClickedSlide: false,
        touchStartForcePreventDefault: false,
        initialSlide: index,
        navigation: {
          nextEl: '.slider-next',
          prevEl: '.slider-prev',
          disabledClass: 'v-btn--disabled',
        },
        breakpoints: {
          1900: {
            slidesPerView: this.expanded ? 1 : this.items_per_view_xl,
          },
          1440: {
            slidesPerView: this.expanded ? 1 : this.items_per_view_lg,
          },
          1200: {
            slidesPerView: this.expanded ? 1 : this.items_per_view_md,
          },
        },
      });

      if (this.slider) {
        this.activeIndex = this.slider.realIndex;
        this.update_slider();

        this.slider.on('transitionStart', () => {
          this.activeIndex = this.slider ? this.slider.realIndex : 0;
        });
      }
    }
  }

  update_slider() {
    if (this.slider) {
      this.slider.update();
    }
  }

  destroy_slider() {
    if (this.slider) {
      this.slider.destroy();
    }
  }
}
