
import {
  Component, Emit, Prop, Ref, Mixins,
} from 'vue-property-decorator';
import { attractions_store, timeslots_store, global_store } from '@/store';
import { create_default_ticket } from '@/modules/tickets/shared/constans';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { VForm } from '@/types';
import { ValidateMixin, PolicyMixin, ErrorHandlereMixin } from '@/mixins';
import { AttractionShared } from '@/modules/attractions/shared/types';
import {
  OrderTypes, TicketSaveStatuses, TicketsStatuses, TimeslotStatuses,
} from '@/enums';
import { Tickets } from '@/modules/tickets/types';
import { AttractionsService } from '@/modules/attractions/services';
import { empty_online_client } from '@/modules/clients/constans';
import { TimeslotSharedType } from '@/modules/timeslots/shared/types';
import moment from 'moment';
import TimeslotOnlineClientForm from './forms/TimeslotOnlineClientForm.vue';
import TimeslotOfflineClientForm from './forms/TimeslotOfflineClientForm.vue';
import { Timeslots } from '../types';
import TimeslotActions from './TimeslotActions.vue';

const { state, actions, mutations } = timeslots_store;
const attractionsService = new AttractionsService();

@Component({
  components: {
    TimeslotActions,
    TimeslotOnlineClientForm,
    TimeslotOfflineClientForm,
  },
})
export default class TimeslotExpandArea extends Mixins(
  ErrorHandlereMixin,
  ValidateMixin,
  PolicyMixin,
) {
  @Ref()
  private readonly form!: VForm

  @Prop()
  item!: Timeslots.Timeslot

  @Prop()
  attraction!: AttractionShared

  @Emit()
  ticket_add(item: TicketsSharedType) {
    return item;
  }

  today: string = moment(new Date()).format('YYYY-MM-DD');

  tab: number | null = null
  oldTimeslot: Timeslots.Timeslot | null = null;
  form_valid = false
  timeslots: TimeslotSharedType[] = [] as TimeslotSharedType[]
  get_code_interval: ReturnType<typeof setInterval> | null = null

  mounted() {
    if (this.can_save) {
      this.load_free_timeslots();
    }
    this.get_code_interval = setInterval(this.initial_code, 50);
  }

  beforeDestroy() {
    this.clear_get_code_interval();
  }

  destroy_component() {
    this.close_timeslot();
    this.$destroy();
  }

  destroyed() {
    mutations.REMOVE_TIMESLOT_FROM_ACTIVES(this.item);
  }

  get attraction_data() {
    return this.item?.attraction || this.attraction;
  }

  get momentDate() {
    return global_store.state.date;
  }

  get time() {
    return global_store.state.time;
  }

  get can_add_new_ticket() {
    return (
      this.tickets.length < this.item.seats
      && [
        TicketsStatuses.ACTIVE, TicketsStatuses.BOOKING,
      ].includes(this.tickets[0].status)
    );
  }

  is_disabled_delete_ticket_btn(ticket: TicketsSharedType) {
    return this.item.tickets.length <= 1 || ticket.status === TicketsStatuses.COMPLETED;
  }

  get_online_ticket(ticket: TicketsSharedType) {
    if (!ticket.client) {
      // eslint-disable-next-line no-param-reassign
      ticket.client = empty_online_client;
    }

    return ticket;
  }

  is_online_order(ticket: Tickets.Ticket): boolean {
    return ticket.order?.type === OrderTypes.ONLINE;
  }

  is_time_has_passed(item: Timeslots.Timeslot) {
    if (this.momentDate < this.today) {
      return true;
    }

    return this.momentDate === this.today && item?.start_time < this.time;
  }

  row_bg_color(item: Timeslots.Timeslot) {
    return this.is_time_has_passed(item) ? 'grey lighten-2' : '';
  }

  is_readonly_ticket(ticket: Tickets.Ticket) {
    return !!ticket.client?.id;
  }

  get is_have_order_of_tickets() {
    return this.item.tickets.some((ticket) => ticket.order);
  }

  get timeslots_statuses() {
    return TimeslotStatuses;
  }

  get can_save() {
    return this.tickets.every((ticket: TicketsSharedType) =>
      TicketSaveStatuses.includes(ticket.status));
  }

  get tickets() {
    const data = this.item.tickets;

    if (!data.length) {
      const ticket = create_default_ticket(this.item);

      this.ticket_add(ticket);
    }

    return data;
  }

  initial_code() {
    const code = state.active_timeslots.find((timeslot) => timeslot.id === this.item.id)?.code;

    if (typeof code === 'string' || typeof code === 'number') {
      this.item.code = code;
      this.clear_get_code_interval();
    }
  }

  clear_get_code_interval() {
    if (this.get_code_interval) {
      clearInterval(this.get_code_interval);
    }
  }

  get expanded() {
    return attractions_store.state.attractions_expand;
  }

  get card_width() {
    switch (this.$vuetify.breakpoint.name) {
      case 'md':
        return '25%';
      case 'lg':
        return '25%';
      case 'xl':
        return '35%';
      default:
        return '25%';
    }
  }

  async change_timeslot(timeslot: TimeslotSharedType) {
    this.oldTimeslot = this.item;

    try {
      await actions.change_timeslot({ old_timeslot: this.item, new_timeslot: timeslot });
    } catch (error) {
      this.handleServerError(error);
    }
  }

  async load_free_timeslots() {
    try {
      this.timeslots = await attractionsService.get_attraction_timeslots({
        id: this.item.attraction_id,
        date: global_store.state.date,
      });
    } catch (error) {
      this.handleServerError(error);
    }
  }

  add_new_ticket() {
    const ticket = create_default_ticket(this.item);

    this.ticket_add(ticket);
    this.tab = this.tab ? this.tab + 1 : 1;
  }

  handle_remove_ticket(item: TicketsSharedType) {
    this.tab = !this.tab ? 0 : this.tab - 1;

    setTimeout(() => {
      this.$emit('ticket_remove', item);

      if (!this.tickets.length) {
        const ticket = create_default_ticket(this.item);

        this.ticket_add(ticket);
      }
    }, 150);
  }

  get_flight_number() {
    return `${this.attraction_data.prefix}-${this.item?.code ?? ''}`;
  }

  async close_timeslot() {
    if (this.oldTimeslot !== null) {
      this.$emit('close', this.oldTimeslot);
    } else {
      this.$emit('close', this.item);
    }
  }
}
