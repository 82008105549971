import { Mutations } from 'vuex-smart-module';
import StatisticsState from './state';
import { Statistics } from '../types';

export default class StatisticsMutations extends Mutations<StatisticsState> {
  SET_DASHBOARD(data: Statistics.Dashboard) {
    this.state.dashboard = data;
  }

  SET_HISTORY(data: Statistics.History) {
    this.state.history = data;
  }
}
