import { Module } from 'vuex-smart-module';
import GlobalActions from './actions';
import GlobalGetters from './getters';
import GlobalMutations from './mutations';
import GlobalState from './state';

export default new Module({
  state: GlobalState,
  mutations: GlobalMutations,
  actions: GlobalActions,
  getters: GlobalGetters,
});
