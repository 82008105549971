import { RolesTranslate } from '@/enums';

export namespace Auth {
  export enum Pages {
    SIGN_IN = 'signin',
    LOGOUT = 'logout'
  }

  export interface Login {
    login: string,
    password: string,
  }

  export interface Token {
    token: string,
  }

  export enum TokenPrefix {
    JWT = 'jwt',
    JWT_LIFE_TIME = 'jwt_life_time'
  }

  export type UserRole = {
    guard_name: string,
    id: number,
    name: typeof RolesTranslate,
  }

  export type User = {
    id: number | null,
    is_active: boolean,
    login: string,
    name: string,
    created_at?: string,
    updated_at?: string,
    roles?: UserRole[],
  }

  export interface AuthResponse {
    authorization: { token: string }, user: User
  }
}
