import { Component, Vue } from 'vue-property-decorator';
import {
  AllPermissions,
  Permissions,
  permissions_by_role,
  Roles,
} from '@/enums';
import { auth_store, global_store } from '@/store';
import momment from 'moment';

@Component
export default class PolicyMixin extends Vue {
  get permissions() {
    return Permissions;
  }

  check_permissions(permission: AllPermissions, date: string = global_store.state.date) {
    if (auth_store.state.user.roles.length > 0) {
      const role = auth_store.state.user.roles[0].name as unknown as Roles;
      const local_date = momment(date).format('YYYY-MM-DD');
      const today = momment(new Date()).format('YYYY-MM-DD');

      if (role === Roles.CASHIER && local_date !== today) {
        return Permissions.Attraction.MODIFY === permission;
      }

      return permissions_by_role[role].includes(permission);
    }

    return false;
  }
}
