import { MiddlewarePayload } from '@/types';

type MiddlewarePipe = (payload: MiddlewarePayload) => void;

function middleware_pipeline(context: MiddlewarePayload, middleware: MiddlewarePipe[], index: number) {
  const next_middleware = middleware[index];

  if (!next_middleware) {
    return context.next;
  }

  return () => {
    const next_pipeline = middleware_pipeline(
      context,
      middleware,
      index + 1,
    );

    next_middleware({ ...context, next_middleware: next_pipeline });
  };
}

export default middleware_pipeline;
