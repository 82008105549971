import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { Clients } from '../types';

export default class ClientsState {
  selected_client: Clients.Client | null = null;
  selected_client_tickets: TicketsSharedType[] = [];
  clients: Clients.Client[] = [];
  toe_tag_clients: Clients.ToeTag[] = [];
  selected_toe_tag_client: Clients.ToeTag | null = null;
  selected_ticket_client: Clients.Client | null = null;
  search_field = '';
}
