import { OrdersSharedType } from '@/modules/orders/shared/types';
import { TicketsSharedType } from '@/modules/tickets/shared/types';
import { tickets_store, worktables_store } from '@/store';
import { CommentDTO } from '@/types';
import { Actions } from 'vuex-smart-module';
import { ErrorHandlereMixin } from '@/mixins';
import { ClientsService } from '../services';
import { Clients } from '../types';
import ClientsGetters from './getters';
import ClientsMutations from './mutations';
import ClientsState from './state';

const clientsService = new ClientsService();
const errorHandlereMixin = new ErrorHandlereMixin();

export default class ClientsActions extends Actions<
  ClientsState,
  ClientsGetters,
  ClientsMutations,
  ClientsActions
> {
  set_client(client: Clients.Client) {
    this.mutations.SELECT_CLIENT(client);
  }

  close_client() {
    this.mutations.CLOSE_SELECTED_CLIENT();
  }

  async create_comment(payload: CommentDTO) {
    try {
      const data = await clientsService.send_comment(payload);

      this.mutations.UPDATE_COMMNETS(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async get_toe_tag_client(toetag_id: number) {
    try {
      const client = await clientsService.get_toe_tag_client(toetag_id);

      this.mutations.SET_MATCHES_IN_TOE_TAG_CLIENT(client.matches);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async get_toe_tag_clients(value?: string) {
    try {
      const data = await clientsService.get_toe_tag_clients(value);

      this.mutations.SET_TOE_TAG_CLIENTS(data);
    } catch (error) {
      errorHandlereMixin.handleServerError(error);
    }
  }

  async edit_client(client: Clients.Client) {
    const data = await clientsService.update_client(client);

    if (data) {
      this.mutations.EDIT_CLIENT(data);
      const timeslots = worktables_store.getters.get_timeslots_by_client_id(client.id as number);

      timeslots.forEach((timeslot) => {
        timeslot.tickets.forEach((ticket) => {
          if (ticket.client?.id === client.id) {
            // eslint-disable-next-line no-param-reassign
            ticket.client = data;
          }
        });

        worktables_store.mutations.UPDATE_WORKTABLE_TIMESLOT(timeslot);
      });
    }
  }

  async get_client_tickets(query: Clients.TicketsFilter) {
    const data = await clientsService.get_client_tickets(query);

    this.mutations.SET_CURRENT_CLIENT_TICKETS(data);
    tickets_store.mutations.SET_TICKETS(data);
  }

  async get_client_orders(client_id: number): Promise<OrdersSharedType[]> {
    const data = await clientsService.get_client_orders(client_id);

    return data;
  }

  async set_client_by_id(client_id: number) {
    const data = await clientsService.get_client_by_id(client_id);

    this.mutations.SELECT_CLIENT(data);
  }

  async edit_ticket(ticket: TicketsSharedType) {
    this.mutations.EDIT_TICKET_IN_CLIENTS_TICKETS(ticket);
  }

  async search_clients(search: string): Promise<Clients.Client[]> {
    const data = await clientsService.search_client(search);

    this.mutations.ADD_SEARCH_CLIENTS(data);

    return data;
  }

  clear_search() {
    this.mutations.CLEAR_SEARCH_CLIENTS();
    this.mutations.REMOVE_TICKET_CLIENT();
  }
}
