import Vue from 'vue';
import * as Vuex from 'vuex';
import GlobalModule from '@/shared/store';
import AuthModule from '@/modules/auth/store';
import WorkspaesModule from '@/modules/worktables/store';
import AttractionsModule from '@/modules/attractions/store';
import StatisticsModule from '@/modules/statistics/store';
import TicketsModule from '@/modules/tickets/store';
import ClientsModule from '@/modules/clients/store';
import InstructorsModule from '@/modules/instructors/store';
import OrdersModule from '@/modules/orders/store';
import TimeslotsModule from '@/modules/timeslots/store';
import { createStore, Module } from 'vuex-smart-module';

Vue.use(Vuex);

export const RootModule = new Module({
  modules: {
    global: GlobalModule,
    auth: AuthModule,
    worktables: WorkspaesModule,
    attractions: AttractionsModule,
    statistics: StatisticsModule,
    tickets: TicketsModule,
    clients: ClientsModule,
    orders: OrdersModule,
    instructors: InstructorsModule,
    timeslots: TimeslotsModule,
  },
});

export const store = createStore(RootModule);

const context = RootModule.context(store);

export const global_store = context.modules.global;
export const auth_store = context.modules.auth;
export const worktables_store = context.modules.worktables;
export const attractions_store = context.modules.attractions;
export const statistics_store = context.modules.statistics;
export const tickets_store = context.modules.tickets;
export const clients_store = context.modules.clients;
export const instructors_store = context.modules.instructors;
export const orders_store = context.modules.orders;
export const timeslots_store = context.modules.timeslots;
