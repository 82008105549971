import { Statistics } from '@/modules/statistics/types';
import { Actions } from 'vuex-smart-module';
import { InstructorsService } from '../services';
import { Instructor, InstructorQuery } from '../types';
import InstructorsGetters from './getters';
import InstructorsMutations from './mutations';
import InstructorsState from './state';

const instructorsService = new InstructorsService();

export default class InstructorsActions extends Actions<
  InstructorsState,
  InstructorsGetters,
  InstructorsMutations,
  InstructorsActions
> {
  clear() {
    this.mutations.CLEAR();
  }

  async add_instructor(payload: Instructor) {
    const data = await instructorsService.add_new(payload);

    return data;
  }

  async get_instructors(payload: InstructorQuery) {
    const data = await instructorsService.get_instructors(payload);

    this.mutations.SET_INSTRUCTORS(data);
  }
}
