import { HttpService } from '@/services/http-service';
import { Response } from '@/types';
import { global_store } from '@/store';
import { Worktables } from '../types';

export class WorktablesService extends HttpService {
  private readonly base_path = 'worktables';

  async get_worktables(): Promise<Worktables.Worktable[]> {
    const { data } = await this.http_client.get(this.base_path);

    return data.data;
  }

  async get_worktable_by_id(id: number) {
    const { data } = await this.http_client.get(`${this.base_path}/${id}`, {
      params: {
        date: global_store.state.date,
      },
    });

    return data.data;
  }

  async create_worktable(payload: Worktables.Worktable): Promise<Worktables.Worktable> {
    const { data } = await this.http_client.post(this.base_path, payload);

    return data.data;
  }

  async delete_worktable(payload: Worktables.Worktable): Promise<Response<null>> {
    const response = await this.http_client.delete(`${this.base_path}/${payload.id}`, {
      data: payload,
    });

    return response;
  }

  async edit_worktable(payload: Worktables.Worktable): Promise<Worktables.Worktable> {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}`, payload);

    return data.data;
  }

  async add_attractions_to_worktable(payload: Worktables.AddAttractionsToWorktableRequest) {
    const { data } = await this.http_client.patch(`${this.base_path}/${payload.id}/attractions`, {
      attractions: payload.attractions,
    });

    return data.data;
  }
}

export const default_worktable = (): Worktables.Worktable => ({
  id: 0,
  title: '',
  attractions: [],
  instructors: [],
});
