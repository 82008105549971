
import {
  Component,
  Mixins,
  Emit,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { ErrorHandlereMixin } from '@/mixins';
import { TextField, TextArea, Table } from '@/components';
import { SimpleDialog } from '@/components/dialogs/simple';
import { clients_store } from '@/store';
import { scrollTop } from '@/utils';
import CreateClientWithoutEmailDialog from '../dialogs/CreateClientWithoutEmailDialog.vue';
import { Clients } from '../../types';
import { phone_format, birthdate_format } from '../../../../filters';
import { ClientsService } from '../../services';

const { state, actions } = clients_store;
const clientsService = new ClientsService();

@Component({
  components: {
    TextField,
    TextArea,
    Table,
    SimpleDialog,
    CreateClientWithoutEmailDialog,
  },
})
export default class FindingClientsInTheManifest extends Mixins(ErrorHandlereMixin) {
  @Prop({})
  client!: Clients.ToeTag;

  @Emit()
  close() {
    this.$nextTick(() => this.selected_client = null);
    return false;
  }

  updated() {
    this.load_matches();
  }

  loading = false;
  selected_client: Clients.Client|null = null;
  show_dialog_create_client_without_email = false;

  get matches() {
    return state.selected_toe_tag_client?.matches || [];
  }

  select_client(client: Clients.Client) {
    this.selected_client = client;
  }

  bold<T>(text: T, formatter?: (text: string) => string) {
    let value: T|string = text;

    if (formatter && typeof text === 'string') {
      value = formatter(text);
    }

    return `<span class="font-weight-bold text-body-1">${value}</span>`;
  }

  conver_text_to_bold
    // eslint-disable-next-line no-undef
    <T extends keyof typeof this.client>(field: T, value: typeof this.client[T]) {
      if (field === 'phone') {
        const phone_string = value?.toString();

        if (phone_string && this.client[field] === value) {
          return this.bold(phone_string, phone_format);
        }

        return phone_format(phone_string);
      }

      if (field === 'birthday') {
        if (this.client[field] === value) {
          return this.bold(value, birthdate_format);
        }

        return birthdate_format(value);
      }

      return this.client[field] === value ? this.bold(value) : value;
    }

  full_name(client: Clients.Client) {
    const firstname = this.conver_text_to_bold<'firstname'>('firstname', client.firstname);
    const surname = this.conver_text_to_bold<'surname'>('surname', client.surname);
    const middlename = this.conver_text_to_bold<'middlename'>('middlename', client.middlename ?? '');

    return `${surname} ${firstname} ${middlename}`;
  }

  birthday(client: Clients.Client) {
    if (!client.birthday) {
      return '-';
    }

    return this.conver_text_to_bold<'birthday'>('birthday', client.birthday);
  }

  email(client: Clients.Client) {
    if (!client.email) {
      return '-';
    }

    return this.conver_text_to_bold<'email'>('email', client.email);
  }

  phone(client: Clients.Client) {
    if (!client.phone) {
      return '-';
    }

    return this.conver_text_to_bold<'phone'>('phone', client.phone);
  }

  async load_matches() {
    if (!this.client?.id) {
      return;
    }

    try {
      this.loading = true;
      actions.get_toe_tag_client(this.client.id);
    } catch (error) {
      this.handleServerError(error);
    } finally {
      this.loading = false;
    }
  }

  email_uniqueness() {
    if (!this.matches.length) {
      return true;
    }

    return !this.matches
      .some((client) =>
        !client.email
          || !this.client.email
          || client.email === this.client.email);
  }

  handle_create_client() {
    if (this.email_uniqueness()) {
      this.create_client(this.client);
    } else {
      this.close();
      this.show_dialog_create_client_without_email = true;
    }
  }

  create_client_without_email() {
    const client_without_email: Clients.ToeTag | Clients.Client = {
      ...this.client,
      email: null,
    };

    this.create_client(client_without_email);
  }

  async create_client(client: Clients.ToeTag | Clients.Client) {
    this.loading = true;

    try {
      const created_client = await clientsService.create_client(client);

      actions.set_client(created_client);
      this.show_success(`Создан новый клиент «${created_client.surname} ${created_client.firstname} ${created_client.middlename}».`);
      this.close();
      setTimeout(scrollTop, 0);
    } catch (error) {
      this.handleServerError(error);
    } finally {
      this.loading = false;
    }
  }

  open_client() {
    if (!this.selected_client?.id) {
      return;
    }

    try {
      this.loading = true;

      actions.set_client_by_id(this.selected_client?.id)
        .then(() => {
          this.close();
        })
        .finally(() => {
          this.loading = false;
        });
    } catch (error) {
      this.handleServerError(error);
    }
  }

  get has_linked_client() {
    return !!this.client?.application?.client_id;
  }
}
