import { Mutations } from 'vuex-smart-module';
import { Orders } from '../types';
import OrdersState from './state';

export default class OrdersMutations extends Mutations<OrdersState> {
  SELECT_ORDER(order: Orders.Order) {
    this.state.selected_order = order;
  }

  CLOSE_SELECTED_ORDER() {
    this.state.selected_order = null;
  }

  UPDATE_COMMNETS(order: Orders.Order) {
    if (this.state.selected_order) {
      this.state.selected_order.comments = order.comments;
    }
  }
}
