import Vue from 'vue';
// import Echo from 'laravel-echo';
import App from './App.vue';
import router from './router';
import { store, auth_store } from './store';
import { vuetify, Mask } from './plugins';
import register_filters from './filters';
// import axios from 'axios';

import './scss/main.scss';

register_filters();

// window.io = require('socket.io-client');

// if (typeof (io) !== 'undefined') {
//   window.Echo = new Echo({
//     broadcaster: 'socket.io',
//     host: process.env.VUE_APP_API_SOCKET_URL ?? 'http://localhost:6001',
//   });

//   window.Echo.connector.socket.on('connect', () => {
//     console.log('Socket Echo connected', window.Echo.socketId());
//   });
//   window.Echo.connector.socket.on('disconnect', (payload: any) => {
//     console.log('Socket Echo disconnected', payload);
//   });
//   window.Echo.connector.socket.on('reconnecting', (attempt: any) => {
//     console.log('Socket Echo reconnecting', attempt);
//   });
// }

Vue.config.productionTip = false;

Vue.use(Mask);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
